.main-wrapper {
    padding: 0.5rem;
    width: 80%;
    margin: 1rem auto auto;
    max-width: 1440px;
}
.main-title {
    font-size: 1.62rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #5c6ac4;
}
.section-title {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.2px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #3745a0;
}
.ur-text {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.2px;
}