.border-color-morningshop {
  border-color: $morningshop-color;
}
.border-color-proteinshop {
  border-color: $proteinshop-color;
}
.border-color-default {
  border-color: $urmart-color;
}

.ur-category-icon {
  margin-top: 15px;
  margin-bottom: 15px;
  list-style: none;

  &__icon {
    display: block;
    height: 50%;
    width: 100%;
    padding: 0.2rem;
    box-sizing: border-box;
  }

  &__img {
    width: 100%;
    overflow: hidden;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    margin: auto;
    margin-bottom: 0.5rem;
    img {
      object-fit: cover;
    }
  }

  &__img.no-border {
    border-width: 0px;
    border-radius: 0px;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    height: 1.3rem;
    line-height: 1.2rem;
    text-align: center;
  }
  &__item {
    padding: 10px;
    max-width: 45%;
    margin-left: 0.5rem;
    &__name {
      text-align: center;
      font-size: 1rem;
      font-weight: normal;
    }
    a {
      display: inline-block;
      font-size: 0px;
      width: 100%;
      max-width: 100px;
    }
  }

  &__list-item {
    padding: 15px;
    &__name {
      text-align: left;
      display: inline;
    }
  }

  &__promotion {
    height: 3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    border-top: 1px solid $grey-light;

    &__icon {
      line-height: 3rem;
      text-align: right;
    }

    &__name {
      line-height: 3rem;
    }
  }

  @include respond-to("screen-sm-min") {
    &__img {
      max-width: 60px;
      max-height: 60px;
      border-width: 0px;
      border-style: none;
    }
  }

  @include respond-to("screen-md-min") {
    &__text {
      font-size: 1.1rem;
    }
  }
}
