.tpfield {
  padding: 1rem;
  height: 30px;
  border: 1px solid gray;
  background-color: #fff;
}

.tpcardform-wrapper {
  @include respond-to("screen-md-min") {
    max-width: 80%;
    margin: auto;
  }
}
.tpcardform {
  overflow: hidden;
  background-color: #ededed;
  &__button-group {
    text-align: right;
    padding: 1rem 0 0 1rem;

    button.cancel {
      margin: 0 1rem 0 0;
      width: 45%;
      height: 3.5rem;
      background: none;
      border-color: transparent;
      color: #464c51;
      text-align: right;
    }
    button.confirm {
      margin: 0;
      width: 45%;
      height: 3.5rem;
    }
  }

  @include respond-to("screen-md-min") {
    &__button-group {
      button.cancel {
        width: 30%;
      }
      button.confirm {
        width: 30%;
      }
    }
  }

  &__title {
    padding: 0rem 0rem 1rem 0rem;
  }
}
