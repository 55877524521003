@function get-theme-color($theme) {
  @if $theme == "morningshop" {
    @return $morningshop-color;
  } @else if $theme == "proteinshop" {
    @return $proteinshop-color;
  } @else {
    @return $urmart-color;
  }
}

@mixin gen-store-color($class, $themeNames) {
  @each $theme in $themeNames {
    $color: get-theme-color($theme);
    .#{$theme}-#{$class} {
      background-color: $color;
    }
  }
}

@mixin gen-theme-class($class, $themeNames) {
  @each $theme in $themeNames {
    $color: get-theme-color($theme);
    .#{$theme}-#{$class} {
      color: $color;
      border-color: $color;
      border-bottom: 3px solid $color;
      height: 100%;
      text-align: center;
      box-sizing: border-box;
      padding: 0px;

      > .ur-store-tab__tab-link {
        color: $color;
        padding: 0;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 45px;
        font-size: 13px;
        width: 100%;
      }
    }

    .#{$theme}-#{$class}-unselected {
      color: $color;
      border-color: $color;
      border-bottom: 3px solid transparent;
      height: 100%;
      text-align: center;
      border-bottom: 1px solid #f0f1fb;

      > .ur-store-tab__tab-link {
        color: $color;
        padding: 0;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 100%;
        line-height: 45px;
        font-size: 13px;
        width: 100%;
      }
    }
  }
}
