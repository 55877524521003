/*!
 * react-responsive-carousel css
 * https://www.npmjs.com/package/react-responsive-carousel 
*/
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer;

  max-width: 15%;

  @include respond-to("screen-md-min") {
    max-width: 5%;
  }
}
.carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: "";
}
.carousel .control-disabled.control-arrow {
  opacity: 0;

  filter: alpha(opacity=0);
  cursor: inherit;
  display: none;
}
.carousel .control-prev.control-arrow {
  left: 0;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}
.carousel .control-next.control-arrow {
  right: 0;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
}
.carousel * {
  box-sizing: border-box;
}

.carousel .carousel {
  position: relative;
}
.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -13px;
  font-size: 18px;
}
.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}
.carousel .thumbs {
  transition: all 0.15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  width: 80px;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #333;
  padding: 2px;
}
.carousel .thumb img {
  vertical-align: top;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  height: 100%;
  transition: height 0.15s ease-in;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}
.carousel .slider-wrapper.axis-vertical {
  display: flex;
}
.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
  height: 100%;
}
.carousel .slider.animated {
  transition: all 0.35s ease-in-out;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  //   height: 200px;
  height: 100%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
}
.ur-homepage__carousel {
  .control-dots {
    position: absolute;
  }
}
.ur-homepage__carousel .carousel .slide img {
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
  object-position: 50% 50%;
  max-height: 576px;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
.carousel .slide .legend {
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  transition: opacity 0.35s ease-in-out;
}
.carousel .control-dots {
  margin: 10px 0;
  text-align: center;
  width: 100%;
}
@include respond-to("screen-md-min") {
  .carousel .control-dots {
    bottom: 0;
  }
}
.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px $urmart-color-light;
  background: $urmart-color;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  background: $urmart-color;
}
.carousel:hover .slide .legend {
  opacity: 1;
}
