%option {
  background-color: $grey-light;
  color: $black;
  border: 1px solid transparent;
  border-radius: 5px;
  display: block;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-size: 0.85rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.ur-filter-popup {
  z-index: 5000;
  position: fixed;
  background-color: $white;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  @include respond-to("screen-md-min") {
    background-color: #ffffff;
    max-height: 75vh;
    .ur-page-header {
      display: none;
    }
    position: relative;
    left: 0;
    z-index: 102;
  }

  input[type="checkbox"] {
    visibility: hidden;
    width: 0px;
    height: 0px;
    padding: 0px;
    margin: 0px;
  }

  &__section {
    height: 100%;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 110px;

    @include respond-to("screen-md-min") {
      height: 75vh;
    }
  }

  &__title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  &__option {
    @extend %option;
    &__selected {
      @extend %option;
      background-color: $white;
      color: $urmart-color;
      border: 1px solid $urmart-color;
    }
  }
  &__button {
    a {
      color: white;
    }
  }
  &__buttongroup {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: $white;
    @include respond-to("screen-md-min") {
      background-color: #ffffff;
    }
  }
}

.ur-filter-bar {
  height: 45px;
  width: 100%;
  font-size: 13px;
  color: #4c4c4e;
  background-color: #ffffff;

  @include respond-to("screen-md-min") {
    z-index: 3066;
    height: 45px;
    font-size: 1rem;
    letter-spacing: 0.2px;

    span {
      line-height: 45px;
    }
  }

  a {
    display: block;
    text-align: center;
    width: 98%;
    border-left: 1px solid $grey-light;
    line-height: 45px;
  }

  &__filter {
    &__options {
      display: block;
      text-align: center;
      width: 98%;
      border-left: 1px solid $grey-light;
      line-height: 45px;
      cursor: pointer;
    }
  }

  &__item {
    height: 100%;
    border-bottom: 1px solid $grey-light;
    padding: 0px;
    &__selected {
      height: 100%;
      padding: 0px;
      box-sizing: border-box;
      border-bottom: 3px solid $urmart-color;
      box-sizing: border-box;
    }
    &__disabled {
      padding: 0px;
      a {
        color: $grey-dark;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
  .disable {
    color: grey;
    opacity: 0.8;
  }
}
