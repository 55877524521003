.ur-product-page {
  &__content {
    width: 100%;
    margin: auto;
    max-width: $maxDesktopWidth;
    padding-bottom: 55px; // add cart button height
  }
  &__title {
    padding: 0.8rem 0.5rem;
    font-size: 1.2rem;
  }
  &__reviews {
    padding: 5px;
  }
  &__left {
    background-color: #fff;
    position: relative;
    img {
      height: 100%;
    }
  }
  &__right {
    background-color: #fff;
  }

  @include respond-to("screen-md-min") {
    &__content {
      width: 90%;
    }
    &__title {
      font-size: 1.8rem;
      padding: 0.8rem 0;
      font-size: 1.5rem;
    }
    &__left {
      padding: 10px;
      overflow: hidden;
      margin-top: 10px;
    }
    &__right {
      padding: 20px 10px 20px 10px;
      overflow: hidden;
    }
  }
}

.width-100 {
  width: 100%;
}

.ur-d-block {
  display: block;
}
