.ur-cart-button {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.9rem;

  @include respond-to("screen-md-min") {
    font-size: 1rem;
  }

  &__badge {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: $urmart-color;
    line-height: 2.2rem;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;

    &__icon {
      font-size: 1.3rem;
      color: $white;
      vertical-align: text-bottom;
      margin-left: 0.2rem;
    }
  }
}
