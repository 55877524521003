.ur-message-popup {
  position: fixed;
  width: 200px;
  max-height: 200px;
  top: calc(50% - 100px);
  right: calc(50% - 100px);
  border-radius: 10px;
  background-color: $black;
  color: $white;
  z-index: 2000;
  padding: 10px;
  text-align: center;
  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &__content {
    &__message {
    }
    &__iconPic {
    }
    &__icon {
      display: "block";
      &__top {
        color: "green";
        font-size: 24px;
      }
      &__bottom {
        color: "white";
        font-size: 48px;
      }
    }
  }
}

/**
* Message styls for notification
*/
.ur-message {
  padding: 1.2rem;
  &__text {
    &__warning {
      color: $red;
    }
    &__notification {
      color: $red;
    }
  }
  &__notification {
    color: $red-orange;
  }
  &__img {
    padding: 10px;
    vertical-align: middle;
    max-width: 200px;
  }
}

.ur-error-message {
  color: $red;
}
