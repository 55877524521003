.ur-review {
  &__popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3000;
    box-sizing: border-box;
    padding: 1rem;
  }
  &__section {
    padding: 1rem;
    margin: auto;
    max-width: 800px;
  }
  &__item {
    border-top: 1px solid $grey-light;
    padding: 10px;
  }

  &__avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: $grey-light;
    text-align: center;
    line-height: 3rem;
  }

  &__star {
    font-size: 1.5rem;

    &__full {
      color: $yellow;
      font-size: 20px;
    }
    &__empty {
      color: #dce5ed;
      font-size: 20px;
    }
  }

  &__button {
    margin: 0;
    border: 0;
    background: white;
    height: 100%;
    width: 100%;
    display: block;
  }
}

.ur-review-page {
  &__layout {
    width: 40%;
    margin: 0 auto;
  }

  &__section {
    padding: 16px;
    &__title {
      margin-right: 24px;
    }
  }

  &__form {
    padding: 24px;
    padding-top: 0;
  }

  &__h1 {
    font-size: 2rem;
  }

  &__icon {
    font-size: 28px;
  }

  &__textarea {
    margin: 0;
    margin-top: 8px;
    border-color: $black;
    border-radius: 0;
    height: 125px;
  }

  &__button-black {
    background: $black;
    padding: 8px;
    width: 100%;
    border-color: $black;
    border-radius: 0;
  }

  &__spacing {
    margin-left: 20px;
  }

  &__popup-red {
    background: $red;
    padding: 4px;
    width: 60%;
    border-color: $red;
  }

  &__content {
    width: 40%;
    margin: 0 auto;
    background: #ffffff;
    padding: 28px 20px 36px 20px;

    @media screen and (min-width: $screen-xxs-min) and (max-width: $screen-md-min) {
      width: 80%;
    }
  }

  &__bottom {
    margin-bottom: 60px;
  }
}
