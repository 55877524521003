.ur-editor-choice {
  box-sizing: border-box;
  padding: 10px;

  img {
    width: 100%;
    max-width: 100%;
    border-radius: 15px;
  }
}
