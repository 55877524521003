// infinite-load item width control
.infinite-item {
  width: 46%;
  @include respond-to("screen-sm-min") {
    width: 32%;
  }
  @include respond-to("screen-md-min") {
    width: 20%;
  }
  @include respond-to("screen-lg-min") {
    width: 15%;
  }
}

// basic styles for card
%ur-product-card {
  border: 1px solid $grey-light;
  border-radius: 3px;
  margin-top: 0.8rem;
  overflow: hidden;
  padding: 0.5rem;
  background-color: #fff;
  cursor: pointer;
}

.ur-product-card {
  @extend %ur-product-card;
  a {
    height: 100%;
    width: 100%;
    display: block;
  }

  &__img {
    width: 100%;
    height: 11rem;
    text-align: center;
    overflow: hidden;
    position: relative;

    img {
      width: auto;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      margin: auto;
    }
  }

  &__img-outOfStock {
    width: 100%;
    height: 11rem;
    overflow: hidden;
    position: relative;
    text-align: center;

    img {
      width: auto;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      margin: auto;
    }

    &:before {
      content: "";
      background-image: url("https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/out-of-stock.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__tag {
    font-size: 0.7rem;
    border-radius: 3px;
    background-color: $red-orange;
    color: $white;
    padding: 0.2rem 0.4rem;
    text-align: center;
    max-width: 40%;
  }

  &__details {
    width: 100%;
    height: 5rem;

    &__button {
      text-align: right;
    }
  }
  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    height: 3rem;
    line-height: 1.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
  }

  &__discount {
    position: absolute;
    top: 0;
    padding: 0.2rem 0.8rem;
    background-color: #ed6347;
    color: #f4f5fa;
    font-size: 0.8rem;
    letter-spacing: 0.2px;
  }
}

.ur-product-card-small {
  @extend %ur-product-card;
  margin-right: 0.2rem;
  position: relative;
  a {
    height: 100%;
    width: 100%;
    display: block;
  }

  &__img {
    width: 100%;
    height: 8.5rem;
    text-align: center;
    overflow: hidden;
    position: relative;

    img {
      width: auto;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      margin: auto;
    }
  }

  &__img-outOfStock {
    width: 100%;
    height: 8.5rem;
    overflow: hidden;
    position: relative;
    text-align: center;

    img {
      width: auto;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      margin: auto;
    }

    &:before {
      content: "";
      background-image: url("https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/out-of-stock.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__tag {
    font-size: 0.6rem;
    border-radius: 3px;
    background-color: $red-orange;
    color: $white;
    padding: 0.2rem 0.4rem;
    text-align: center;
    max-width: 40%;
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    height: 3rem;
    line-height: 1.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
  }

  &__details {
    width: 100%;
    height: 5rem;

    &__button {
      text-align: center;
    }
  }

  &__timer {
    background-color: $red-orange;
    color: $white;
    padding: 5px 10px;
    float: left;
  }

  &__discount {
    position: absolute;
    top: 0;
    padding: 0.2rem 0.8rem;
    background-color: #ed6347;
    color: #f4f5fa;
    font-size: 0.8rem;
    letter-spacing: 0.2px;
  }
}

.group-product-card {
  &__wrapper {
    height: calc(100% - 0.8rem);
  }
  &__content {
    height: calc(100% - 1.14rem);
  }
}
