.ur-product-price {
  white-space: nowrap;
  &__page {
    margin: 0.3rem;
  }
  &__viphide {
    color: $red;
    font-size: 1.2rem;
  }

  &__old {
    color: #a6a6a6;
    font-size: 0.8rem;

    &__page {
      color: #a6a6a6;
    }
  }

  &__new {
    font-size: 1.2rem;
    color: #ed6347;
    margin-right: 5px;
    &__page {
      color: #ed6347;
      margin: 0.3rem;
      font-size: 1.5rem;
    }
  }

  &__discount {
    background-color: #ed6347;
    color: $white;
    font-size: 1rem;
    margin-left: 0.5rem;
    line-height: 1.2rem;
    padding: 0 0.5rem;
  }

  @include respond-to("screen-xxs-min") {
    &__viphide {
      color: $red;
      font-size: 1.5rem;
    }

    &__old {
      color: #a6a6a6;
      font-size: 1rem;

      &__page {
        color: #a6a6a6;
      }
    }

    &__new {
      font-size: 1.5rem;
      color: #ed6347;
      margin-right: 5px;
      &__page {
        color: #ed6347;
        margin-right: 5px;
        font-size: 1.5rem;
      }
    }

    &__discount {
      background-color: #ed6347;
      color: $white;
      font-size: 1rem;
      margin-left: 0.5rem;
      line-height: 1.2rem;
      padding: 0 0.5rem;
    }
  }

  @include respond-to("screen-md-min") {
    &__old {
      &__page {
        font-size: 1.5rem;
      }
    }
    &__new {
      &__page {
        margin-right: 5px;
        font-size: 2rem;
      }
    }
    &__discount {
      line-height: 1.5rem;
    }
  }

  @include respond-to("screen-xl-min") {
    &__old {
      font-size: 1.1rem;
    }
    &__new {
      font-size: 1.6rem;
    }
  }
}
