.ur-category {
  &__sidebar {
    background-color: #ffffff;
    padding-top: 10px;
    max-height: 80%;

    &__item {
      min-height: 30px;
      line-height: 30px;
      border-radius: 15px;
      text-align: center;
      margin-bottom: 10px;
      color: $black;
      overflow: hidden;
      display: table;

      span,
      i {
        display: table-cell;
        padding: 0px;
      }
      i {
        font-size: 1.4rem;
      }
      span {
        width: 80%;
        font-size: 0.8rem;
      }
      i {
        width: 20%;
        vertical-align: middle;
      }
      a {
        width: 100%;
        height: 100%;
        white-space: nowrap;
        display: table-row;
      }
    }
    &__selected {
      display: table;

      span {
        width: 100%;
        display: table-cell;
        padding: 0px;
      }
      a {
        width: 100%;
        height: 100%;
        white-space: nowrap;
        display: table-row;
      }

      &__morningshop {
        background-color: $morningshop-color;
        color: $white;
        &:hover {
          color: $white;
        }
      }

      &__proteinshop {
        background-color: $proteinshop-color;
        color: $white;
        &:hover {
          color: $white;
        }
      }

      &__default {
        background-color: $urmart-color;
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
  &__link-color {
    a {
      color: #0000ee;
      text-decoration: underline;
    }
  }
}

.ur-categorylist {
  &__menu {
    max-width: 100%;
    border: 1px solid $urmart-color-light;
    background-color: #ffffff;

    &__fixed {
      width: 30%;
      position: fixed;
      top: 20%;
    }

    &__item {
      position: relative;
      height: 1.5rem;
      line-height: 1.5rem;
      border-bottom: 1px solid $urmart-color-light;
      padding: 1rem;
      cursor: pointer;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }
      span {
        display: inline-block;
        width: 78%;
        height: 100%;
        vertical-align: middle;
      }
      i {
        width: 20%;
        text-align: right;
        vertical-align: middle;
      }
      &:hover {
        background-color: $urmart-color;
        color: $white;
        a {
          color: $white;
        }
      }
      &:hover .ur-categorylist__submenu__item {
        a {
          color: $black;
        }
      }
    }
  }
  &__submenu {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 6010;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 2px 0px 8px $grey-dark;
    padding: 1rem;
    border-radius: 3px;

    &__item {
      cursor: pointer;
      padding: 0.5rem;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }

      &:hover {
        background-color: $urmart-color;
        & ~ .__menu__item {
          background-color: $urmart-color;
        }
        a {
          color: $white !important;
        }
      }
    }
    &__expand {
      height: 7.5rem;
    }
  }
}
.ur-category-desktop {
  &__section {
    &__content {
      padding: 0px 10% 0px 10%;
    }
  }

  &__others {
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;

    &__title {
      margin-bottom: 2rem;
    }

    &__content {
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}

/* width */
.ur-category-desktop__others__content::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.ur-category-desktop__others__content::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
.ur-category-desktop__others__content::-webkit-scrollbar-thumb {
  background: $urmart-color-extra-light;
}

/* Handle on hover */
.ur-category-desktop__others__content::-webkit-scrollbar-thumb:hover {
  background: $urmart-color-extra-light;
}

%item {
  width: 100%;
  display: block;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.8rem;
  text-align: center;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

%small-category {
  width: 90px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  max-height: 60%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.small-category {
  &-morningshop {
    @extend %small-category;
    border: 1px solid $morningshop-color;
    &__item {
      a {
        @extend %item;
        &:hover {
          background-color: $morningshop-color;
          color: #ffffff;
        }
      }
    }
  }
  &-proteinshop {
    @extend %small-category;
    border: 1px solid $proteinshop-color;
    &__item {
      a {
        @extend %item;
        &:hover {
          background-color: $proteinshop-color;
          color: #ffffff;
        }
      }
    }
  }
  &-default {
    @extend %small-category;
    border: 1px solid $urmart-color;
    &__item {
      a {
        @extend %item;
        &:hover {
          background-color: $urmart-color;
          color: #ffffff;
        }
      }
    }
  }
}

.small-category-bigger {
  width: 100%;

  &-morningshop {
    background-color: #ffffff;
    margin-top: 1rem;
    border: 1px solid $morningshop-color;
  }
  &-proteinshop {
    background-color: #ffffff;
    margin-top: 1rem;
    border: 1px solid $proteinshop-color;
  }
  &-default {
    background-color: #ffffff;
    margin-top: 1rem;
    border: 1px solid $urmart-color;
  }
}
