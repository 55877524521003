.ur-banner {
  position: relative;
  &__content {
    max-width: $maxDesktopWidth;
    margin: auto;

    @include respond-to("screen-md-min") {
      width: 80%;
    }
  }
  &:nth-of-type(odd) {
    background-color: $white;
  }
  &__title {
    font-weight: bold;
    color: $black;
    padding: 1.25rem 0.625rem 0.625rem 0.625rem;
  }

  &__image {
    width: 100%;
    overflow: hidden;

    a {
      width: 100%;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__see-all {
    padding: 1.25rem 0 0.625rem 0.625rem;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      vertical-align: middle;
    }
  }
}

.ur-section {
  padding: 10px;

  &__title {
    position: relative;
    font-weight: bold;
    color: $black;
    padding: 20px 0px 10px 10px;

    &__see {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  &__banner {
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.ur-cart-banner {
  display: flex;
  cursor: pointer;

  &__vipcard-img {
    flex: 3;
    position: relative;
    background-color: #2b2c2a;

    &--big {
      display: block;
      width: 100%;
      @include respond-to("screen-sm-max") {
        display: none;
      }
    }
    &--small {
      display: none;
      @include respond-to("screen-sm-max") {
        display: block;
      }
    }
  }

  &__non-vip {
    display: block;
    position: relative;
    cursor: pointer;
    @media (max-width: 425px) {
      display: none;
    }

    &__img-wrap {
      position: relative;

      img {
        width: 100%;
      }
      p {
        position: absolute;
        bottom: 2%;
        left: 35%;
        color: #eee;
        @media (max-width: 750px) {
          left: 30%;
          font-size: 12px;
        }
      }
    }

    &__text {
      position: absolute;
      right: 2%;
      bottom: 10%;
      font-size: 22px;
      font-weight: 600;

      @media (max-width: 1060px) {
        font-size: 20px;
      }
      @media (max-width: 860px) {
        font-size: 17px;
      }
      @media (max-width: 750px) {
        font-size: 15px;
      }
      @media (max-width: 650px) {
        font-size: 12px;
      }
    }
    &__reversed-text {
      background-color: black;
      color: #eee;
      padding: 0 4px;
    }
  }
}

.ur-cart-banner-mobile {
  display: none;
  @media (max-width: 425px) {
    display: block;
  }

  background-color: #2b2c2a;
  &__top-img {
  }
  &__bottom-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  &__bottom {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }
  &__vip-hand {
    max-width: 100%;
    height: auto;
    margin-right: 15px;
  }
  &__text {
    border-left: 1px solid black;
    padding-left: 10px;
  }
  &__reversed-text {
    background-color: #2b2c2a;
    color: #eee;
    font-weight: 700;
    padding: 0 4px;
  }
}

.ur-cart-banner-vip-web {
  &__product {
    display: flex;
    flex: 1;
    align-items: center;
    @include respond-to("screen-sm-max") {
      display: none;
    }

    &__img {
      flex: 1;
      > img {
        width: 100%;
      }
    }
  }

  &__detail {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__title {
      display: inline-block;
      padding: 0 5px;
      background-color: #2b2c2a;
      font-weight: 600;
      font-size: 20px;
      color: #e8cfa8;
      margin-bottom: 4px;
      @media (max-width: 760px) {
        font-size: 18px;
      }
      @media (max-width: 600px) {
        font-size: 14px;
      }
      @media (max-width: 425px) {
        font-size: 12px;
      }
    }
    &__price {
      font-weight: 600;
      color: #2b2c2a;
      font-size: 25px;
      margin-bottom: 4px;
      @media (max-width: 760px) {
        font-size: 22px;
      }
      @media (max-width: 600px) {
        font-size: 18px;
      }
      @media (max-width: 425px) {
        font-size: 12px;
      }
    }
    &__name {
      font-weight: 600;
      color: #2b2c2a;
      font-size: 12px;
    }
  }
}

.ur-cart-banner-vip-mobile {
  &__product {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    min-width: 0;
    @include respond-to("screen-md-min") {
      display: none;
    }

    &__img {
      height: 65%;
      overflow: hidden;
      > img {
        width: auto;
        max-height: 100%;
      }
    }
  }

  &__detail {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 0;

    &__title {
      background-color: #2b2c2a;
      font-weight: 600;
      color: #e8cfa8;
    }
    &__price-wrap {
      display: flex;
      align-items: center;
      margin-top: 5px;
      font-size: 25px;
      @media (max-width: 600px) {
        font-size: 16px;
      }
      @media (max-width: 425px) {
        font-size: 12px;
      }
    }
    &__price {
      font-weight: 600;
      color: #2b2c2a;
    }
    &__name {
      font-weight: 600;
      color: #2b2c2a;
      width: 100%;
      padding: 0 5px;
      overflow: hidden;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
      @media (max-width: 600px) {
        font-size: 14px;
      }
      @media (max-width: 425px) {
        font-size: 12px;
      }
    }
  }
}
