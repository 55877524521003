/**
* Facebook Login button
*/
.ur-facebook {
  background-color: rgb(58, 89, 152);
  border-color: rgb(58, 89, 152);
  width: 100%;
  color: $white;
  height: 3rem;
  line-height: 3rem;
  font-size: 1rem;

  &__icon {
    width: 20px;
    height: 20px;
  }
}

#urfblogin {
  &:hover {
    background-color: rgb(58, 89, 152);
    border-color: rgb(58, 89, 152);
  }
}
