@import "./modules/form";
@import "./modules/facebook_button";
@import "./modules/qty_button";
@import "./modules/price";
@import "./modules/message";
@import "./modules/product";
@import "./modules/category_icon";
@import "./modules/member";
@import "./modules/category";
@import "./modules/filter";
@import "./modules/loading";
@import "./modules/order";
@import "./modules/review";
@import "./modules/cart_button";
@import "./modules/button";
@import "./modules/banner";
@import "./modules/productlist-page";
@import "./modules/pagination";
@import "./modules/modal";
@import "./modules/editor_choice";
@import "./modules/coupon";
@import "./modules/product_card";
@import "./modules/wishlist_button";
@import "./modules/limited_items";
@import "./modules/radio_button";
@import "./modules/carousel.scss";
@import "./modules/react-tabs.scss";
@import "./modules/creditcard_form";

.ur-info-page {
  padding: 0.5rem;
}
.ur-tag {
  background-color: $red-orange;
  color: $white;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap;
}
.ur-member-button {
  text-align: center;
  background-color: $urmart-color;
  color: #ffffff;
  border-radius: 3px;
  font-size: 1rem;
  height: 3rem;
  line-height: 3rem;

  &:hover {
    color: #ffffff;
    background-color: $urmart-color;
  }
}
.ur-cart-message {
  padding: 0.5rem;
  background-color: #f9f6c8;

  &__tag {
    background-color: #ed6347;
    color: #ffffff;
    border-radius: 2px;
  }
  &__text {
    color: #464c51;
    margin-left: 0.5rem;
  }
}
.ur-store-logo {
  width: 30px;
  height: 30px;
  vertical-align: sub;
  margin-right: 10px;

  @include respond-to("screen-md-min") {
    width: 40px;
    height: 40px;
  }
}

.ur-promotion-bar {
  height: 50px;
  box-sizing: border-box;
  background-color: $red-orange;
  color: $white;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  width: 100%;
  top: 0px;
  &__text {
    text-align: center;
  }

  &__close {
    text-align: right;
    cursor: pointer;
  }
}

.ur-blocklevel-link {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.menu-item-wrapper {
  width: 180px;
}

.ur-cart {
  background-color: $grey-light;
  padding: 15px;

  &__count {
    padding: 10px;
    font-size: 1rem;
    background-color: #ffffff;
  }
  &__title {
    font-size: 1rem;
  }
}
.default-color {
  color: $urmart-color;
}
.protein-color {
  color: $proteinshop-color;
}
.morningshop-color {
  color: $morningshop-color;
}
.ur-product-info {
  border: 1px solid $grey-light;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 15px 0px 15px 0px;
  font-size: 0.8125rem;
  color: #464c51;
  overflow: hidden;

  &__wrapper {
    padding: 1rem;
    background-color: $white;
  }
  &__item {
    border-bottom: 1px solid $grey-light;
    padding: 8px 5px;
    span {
      display: inline-block;
      vertical-align: middle;
      padding-left: 5px;
    }
    i {
      vertical-align: middle;
      color: $urmart-color;
      font-size: 1.57rem;
    }
  }

  &__info {
    padding: 5px;
    height: 100%;
  }

  @include respond-to("screen-md-min") {
    &__wrapper {
      padding: 0;
      background-color: #ffffff;
    }
    &__item {
      font-size: 1rem;
    }
  }
}

.ur-register {
  padding: 0.8rem;
  box-sizing: border-box;
  &__section {
    margin: 2rem 0 2rem 0;
  }
}

/**
* checkout navigation styles
*/
.ur-checkout-step {
  height: 45px;
  font-size: 0.875rem;

  &__bar {
    padding: 0px;
    text-align: center;
    border-bottom: 1px solid #e6e8f4;
    background-color: #ffffff;
    a {
      display: block;
      line-height: 45px;
      color: #e6e8f4;
    }

    &__active {
      @at-root a#{&} {
        color: $urmart-color;
        border-bottom: 3px solid $urmart-color;
      }
    }
    &__inactive {
      @at-root a#{&} {
        color: $grey-medium;
      }
    }
  }
}
.ur-cvs-choose {
  &__form {
    border: 1px solid #e6e8f4;
    border-radius: 5px;
    margin-right: 5px;
    max-width: 200px;
    margin-right: 5px;
    &__button {
      background-color: #ffffff;
      border: none;
    }
  }
  &__button {
    border: 1px solid #e6e8f4;
    border-radius: 5px;
    max-width: 200px;
    text-align: center;
    padding: 10px 0px 10px 0px;
    img {
      width: auto;
    }
  }
  img {
    height: 40px;
  }
}
/**
* checkout content styles e.g cartinfo, shipping...
*/
.ur-checkout-content {
  &__section {
    padding: 15px;

    &__title {
      font-size: 18px;
      font-weight: bold;
      color: $black;
      border-bottom: 1px solid #d0d4ef;
      margin: 10px 0 10px 0;
      padding: 0px 0px 10px 0px;
    }
    &.background-grey {
      background-color: #f4f5fa;
    }
  }
}
.hidden {
  display: none;
}

.ur-slider-wrapper {
  height: "200px";
  backgroundcolor: "grey";
}

%scrollTop {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  bottom: 144px;
  right: 1rem;
  text-align: center;
  color: $white;
  background-color: $urmart-color;
  z-index: 2077;
  cursor: pointer;
  line-height: 40px;
  display: none;
  i {
    vertical-align: middle;
    font-size: 1.5rem;
    margin-top: -5px;
  }
  @include respond-to("screen-md-min") {
    width: 50px;
    height: 50px;
    line-height: 50px;
    right: 1.2rem;

    i {
      vertical-align: middle;
      font-size: 2rem;
      margin-top: -5px;
    }
  }
}
.ur-scroll-top {
  &__morningshop {
    @extend %scrollTop;
    background-color: $morningshop-color;
  }
  &__default {
    @extend %scrollTop;
    background-color: $urmart-color;
  }
  &__proteinshop {
    @extend %scrollTop;
    background-color: $proteinshop-color;
  }
}

.ur-disabled-input {
  border: none;
}
.selected {
  background-color: rgab(128, 128, 128, 0.5);
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
