/*
Form
*/
.ur-form-control {
  &__group {
    position: relative;
    margin: 10px 8px 10px 0px;
    line-height: 1rem;
    padding: 5px 0px 5px 0px;

    label {
      color: $black;
    }
    textarea {
      margin: 5px 0 5px 0;
      width: 100%;
      background-color: #ffffff;
    }
    select {
      width: 100%;
    }
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="tel"] {
      width: 100%;
    }
    input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    /* When the radio button is checked, add a blue background */
    input[type="radio"]:checked ~ .ur-form-control__checkmark {
      background-color: $urmart-color;
    }
    &__required {
      color: $red;
      line-height: 1.1rem;
    }
  }
  &__message {
    color: red;
  }
  &__button {
    text-align: right;
    margin-top: 0.5rem;
    button {
      white-space: normal;
      font-size: 1.14rem;
      height: 3rem;
      line-height: 3rem;
    }

    a {
      color: $white;
    }
  }

  &__checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: #e8e8e8;
    border-radius: 50%;
    cursor: pointer;
  }
  &__text {
    display: inline-block;
    margin-left: 22px;
  }
}

.ur-register-form {
  &__group {
    margin-top: 1rem;
  }
  &__required {
    font-size: 0.9rem;
    margin-left: 5px;
    color: $red;
  }
  input {
    width: 100%;
  }
  button {
    margin-top: 0;
    margin-bottom: 0;
    height: 40px;
  }
  label {
    div {
      margin-top: 0.6rem;
    }
  }
  &__error {
    color: $red;
    font-size: 1rem;
    margin-top: 0.5rem;
    i {
      vertical-align: -25%;
      font-size: 1.5rem;
      margin-right: 2px;
    }
  }
  &__notification {
    color: $notification-text-color;
    font-size: 0.9rem;
    margin-left: 5px;
  }
}

.ur-form-eye {
  width: 1.75rem;
  height: 1.75rem;

  @include respond-to("screen-md-min") {
    width: 2rem;
    height: 2rem;
  }
}
