/*!
 * Place major components here!
 * The major components are those which define the structure of the page and generally do not repeat itself in the page,
 * Like headers, footers, sidebar, body, etc.
 * These major components form the page Layout and hence, referred to as Layout rules.
 * You should not change the file here unless the whole design are change
*/
@import "./layout/flexboxgrid.scss"; // grid system
@import "./layout/page_header"; // header
@import "./layout/footer"; // footer
@import "./layout/five_tabs_navigation"; // navigation
@import "./layout/store_tab"; // 館別 tab
@import "./layout/common_page"; // basic style for all pages
@import "./layout/home_page";
@import "./layout/category_page";
@import "./layout/customer_page";
@import "./layout/product_page";
@import "./layout/serach_page";
