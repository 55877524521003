.modal {
  padding: 2rem 1rem 1rem 1rem;
}
.modal > .header {
  width: 100%;
  font-size: 1.2rem;
  color: $urmart-color;
  text-align: left;
  line-height: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem;
}
.modal > .content {
  width: 100%;
  font-size: 1.1rem;
  text-align: center;
  padding-left: 2px;
  letter-spacing: 0.2px;
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.modal > .actions {
  width: 100%;
  margin: auto;
  text-align: center;

  button {
    background-color: $red;
    color: $white;
    border-color: $red;
    height: 2.5rem;
    width: 100%;
  }

  @include respond-to("screen-md-min") {
    width: 50%;
  }

  .leave-button {
    background-color: #ffffff;
    color: #212121;
    border: none;
    border-radius: 0px;
    margin-right: 5px;
    margin-left: 5px;
    height: 40px;
    line-height: 40px;
    width: 48%;
  }
  .action-button {
    background-color: #494949;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    margin-right: 5px;
    margin-left: 5px;
    height: 40px;
    line-height: 40px;
    width: 48%;
  }

  @include respond-to("screen-md-min") {
    .leave-button {
      border-radius: 3px;
    }
    .action-button {
      background-color: #131b6b;
      color: #ffffff;
    }
  }
}
.modal > .halfbutton {
  width: 100%;
  margin: auto;
  text-align: center;

  button {
    width: 45%;
    margin-right: 0.5rem;
  }
}
.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 5px;
  top: 5px;
  font-size: 1/6rem;
  background: #ffffff;
}
.modal .action-button {
  width: 48%;
  font-size: 1rem;
  margin: 0.2rem;
  border: 2px solid $urmart-color;
  background-color: $urmart-color;
}

.modal .cancel-button {
  width: 48%;
  font-size: 1rem;
  margin: 0.2rem;
  background-color: #ffffff;
  border: 2px solid $urmart-color;
  color: $urmart-color;
}

// overridr react-js popup style
.popup-content {
  max-height: 70vh;
  overflow-x: hidden;
  background-color: #ffffff;
  width: 70%;
  @include respond-to("screen-md-min") {
    width: 50%;
  }
}

.ur-adult-content {
  background-color: #222222;
  border-radius: 1rem;
  color: $white;

  img {
    display: none;
  }

  &__alert {
    color: #e90f0f;
    font-size: 1.5rem;
  }
  @include respond-to("screen-md-min") {
    img {
      display: flex;
    }
    &__alert {
      display: none;
    }
  }
}

.modal .iconImg {
  .img {
    background-image: url("https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/present_64.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 64px;
    height: 64px;
    margin: auto;
  }
}
