@import "../mixins/store-color";

$themeNames: "urmart", "morningshop", "proteinshop";

/**
* Store tab setting
*/
.ur-store-tab {
  height: 45px;
  display: flex;
  font-size: 12px;
  width: 100%;
  background-color: #ffffff;

  @include respond-to("screen-md-min") {
    display: none;
  }
}

/*
* Dynamic create *-tab class with theme color
*/
@include gen-theme-class("tab", $themeNames);
