.ur-homepage {
  width: 100%;
  background-color: $urmart-color-extra-light;

  @include respond-to("screen-md-min") {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__top {
    width: 100%;
    margin: auto;
    max-width: $maxDesktopWidth;

    @include respond-to("screen-md-min") {
      width: 90%;

      &__slider {
        margin-left: 1rem;
      }
    }
  }

  &__wrapper {
    width: 100%;
    padding-bottom: 1.5rem;

    &:nth-of-type(2n + 1) {
      background-color: $white;
    }
    &:nth-of-type(2n) {
      background-color: #ffffff;
    }
  }

  &__section {
    width: 100%;
    margin: auto;
    max-width: $maxDesktopWidth;

    @include respond-to("screen-md-min") {
      width: 80%;
    }
  }

  &__carousel {
    height: 200px;

    @include respond-to("screen-md-min") {
      height: 500px;
    }

    @include respond-to("screen-md-min") {
      height: auto;
      max-height: 576px;
    }
  }

  &__influid {
    width: 100%;
    margin: auto;
    max-width: $maxDesktopWidth;

    @include respond-to("screen-md-min") {
      width: 80%;
    }
  }

  &__pointer {
    cursor: pointer;
  }
}
