.ur-limited-items {
  &__timer {
    color: #e93d16;
    font-weight: 800;
    font-size: 1rem;

    span {
      &:nth-of-type(2) {
        margin-right: 0.2rem;
        margin-left: 0.2rem;
      }
      &:nth-of-type(odd) {
        background-color: #e93d16;
        color: $white;
        line-height: 1.2rem;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        border-radius: 0.2rem;
      }
    }

    @include respond-to("screen-xxs-min") {
      font-size: 1.1rem;
    }

    @include respond-to("screen-md-min") {
      font-size: 1.2rem;
    }
  }

  &__seeall {
    color: $red;
    position: relative;
    font-size: 0.8rem;
    display: flex;

    i {
      vertical-align: sub;
      font-size: 1.1rem;
    }

    span {
      background-color: transparent;
      border: 1px solid $red;
      border-radius: 1rem;
      padding-left: 0.6rem;
    }
    &:after {
      content: "N";
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      color: $white;
      position: absolute;
      top: -0.5rem;
      right: -0.2rem;
      background-color: #e93d16;
      text-align: center;
      line-height: 1rem;
      font-size: 0.6rem;
    }

    @include respond-to("screen-md-min") {
      font-size: 1rem;
      i {
        vertical-align: middle;
        font-size: 1.2rem;
      }
      &:after {
        font-size: 0.75rem;
        width: 1.1rem;
        height: 1.1rem;
        line-height: 1.1rem;
        top: -0.6rem;
      }
    }
  }
}
