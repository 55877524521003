.ur-coupon-list .ur-page-header {
  z-index: initial;
  width: 100%;

  @include respond-to("screen-md-min") {
    display: none;
  }
}

.ur-coupon-tabs {
  @include respond-to("screen-md-min") {
    background-color: $grey-light;
  }
}
