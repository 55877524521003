.ur-loading-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.ur-loading-wrapper__overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 6000;
  background-color: rgba(250, 250, 250, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
