.ur-pagination {
  padding: 2rem;
  &__pages {
    cursor: pointer;
    border-radius: 3px;

    a {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
  &__active {
    border-width: 1px;
    border-style: solid;
    a {
      color: $white;
    }
  }
}

.background-morningshop {
  background-color: $morningshop-color;
}
.background-proteinshop {
  background-color: $proteinshop-color;
}
.background-default {
  background-color: $urmart-color;
}
