.ur-product-list {
  overflow: hidden;

  &__item {
    position: relative;
    background-color: #fff;
    margin: 10px 0px 10px 0px;
    border: 1px solid $grey-light;
    border-radius: 5px;
    height: 10rem;
    box-sizing: border-box;
    padding: 5px 0px 5px 0px;

    &__price {
      color: #ed6347;
      font-size: 1rem;
    }
    &__qty {
      color: #ababab;
      font-size: 1rem;
    }
    &__img {
      padding: 5px;
      height: 100%;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      text-align: center;
      max-height: 150px;

      img {
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__name {
      font-size: 1rem;
      box-sizing: border-box;
      height: 6rem;
    }

    &__content {
    }

    @include respond-to("screen-md-min") {
      &__img {
        max-height: 200px;
      }
      &__name {
        font-size: 1.2rem;
      }
      &__price {
        font-size: 1.3rem;
      }
    }
  }

  &__qty {
    text-align: center;
    font-size: 20px;
    color: $urmart-color;
  }
  &__button {
    width: 40px;
    height: 40px;
    color: $urmart-color;
    font-size: 18px;
    border: none;

    &__circle {
      width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 50%;
      color: #ffffff;
      background-color: $urmart-color;
      text-align: center;
    }
  }

  &__remove {
    position: absolute;
    top: 5px;
    right: 5px;
    text-align: center;
  }

  &__discount {
    position: absolute;
    top: 8px;
    padding: 3px 13px 3px 10px;
    background-color: #ed6347;
    color: #f4f5fa;
    font-size: 12px;
    letter-spacing: 0.2px;
  }
}

%arrow {
  display: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  top: 45%;
  position: absolute;
  z-index: 1000;
  border: 1px solid $grey-light;
  box-shadow: 0 0 5px $grey-medium;
  background-color: $white;
  color: $black;

  i {
    vertical-align: middle;
  }
  &:hover {
    width: 2.5rem;
    height: 2.5rem;
    transition: width 0.3s, height 0.3s, line-height 0.3s;
  }
}

.ur-horizantal-scroll-wrapper {
  position: relative;

  &__content {
    overflow-x: scroll;
    overflow-y: hidden;
    // only for Safari, let scrolling be smooth
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include respond-to("screen-sm-min") {
    }
    @include respond-to("screen-md-min") {
      overflow-x: hidden;
    }
  }

  &__arrow-right {
    @extend %arrow;
    right: 0;
  }
  &__arrow-left {
    @extend %arrow;
    left: 0;
  }
}

.ur-productpage-button {
  border: 2px solid $urmart-color-light;
  border-radius: 5px;
  height: 2.2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  i {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3rem;
    color: rgb(144, 151, 206);
    font-size: 0.8rem;
    letter-spacing: 0.5px;
  }
}

.ur-yellow {
  font-size: 24px;
  color: rgb(255, 187, 0);
}
.ur-productpage-tab {
  position: fixed;
  bottom: 0px;
  z-index: 998;
  background-color: #fff;
  width: 100%;

  @include respond-to("screen-md-min") {
    display: none;
  }

  &__fake {
    width: 100%;
    height: 60px;
    background-color: #fff;
    padding-bottom: env(safe-area-inset-bottom);
  }
  &__wrapper {
    margin: 0;
    border-top: 1px solid #dce5ed;
    box-sizing: border-box;
    padding: 10px;
    height: 55px;
    max-width: $maxDesktopWidth;
  }
  &__item {
    text-align: center;
  }
}

.ur-description {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding-bottom: 3rem;
  font-size: 1.2rem;
  color: $black;
  padding: 2rem 1rem;

  img,
  iframe {
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  p,
  div {
    font-size: 1.2rem;
    padding: 0.5rem;
    color: $black;
    max-width: 800px;
    margin: auto;
  }

  ul {
    li {
      padding: 0.5rem;
      color: $black;
      font-size: 1.2rem;
    }
  }

  @include respond-to("screen-xl-min") {
    max-width: $maxDesktopWidth;
  }
}

.ur-group-product-list {
  flex-wrap: nowrap;
  padding: 1rem;

  hr {
    color: $grey-light;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 1.2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
  }

  &__content {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid $grey-light;
  }

  &__item {
    &__name {
      margin-bottom: 0.2rem;
    }
    &__detail {
      height: 3rem;
    }
    &__price {
      font-size: 1.5rem;
      height: 2rem;
      color: $red;
    }

    &__show {
      height: 100%;
      display: flex;
      position: relative;
    }

    &__hide {
      display: none;
    }

    &__date {
      background-color: #b0b7d6;
      padding: 0.3rem;
      p {
        background-color: #bbc2dc;
        color: #000000;
        padding: 0.3rem;
        font-size: 0.9rem;
      }
    }
    &__img {
      img {
        width: 100%;
        max-width: 8rem;
      }
    }
  }

  &__qty {
    text-align: center;
    font-size: 20px;
    color: $urmart-color;
  }

  &__options {
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
  }

  @media screen and (min-width: $screen-md-min) {
    &__item {
      &__name {
        font-size: 1.2rem;
        margin-bottom: 0.2rem;
      }

      &__detail {
        height: 3rem;
      }
      &__price {
        font-size: 1.5rem;
        height: 2rem;
        color: $red;
      }

      &__show {
        height: 100%;
        display: flex;
        position: relative;
      }
      &__hide {
        display: none;
      }
    }
  }
}

.ur-product-slider {
  height: 400px;
  overflow: hidden;
  text-align: center;
  .carousel {
    .slide {
      img {
        object-fit: contain;
        height: 100%;
      }
    }
    .control-dots {
      position: absolute;
    }
  }

  @include respond-to("screen-md-min") {
    height: 576px;
  }
}

.ur-product-button {
  margin: 0.8rem 0;
}

.ur-discount-label {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: $red-orange;
  color: $white;
  font-size: 1.2rem;
  padding: 3px 13px 3px 10px;
}

.ur-discount-timer {
  background-color: $morningshop-color;
  color: $white;
  line-height: 2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  &__text {
    font-size: 1rem;
  }
  &__time {
    font-size: 1rem;
  }
  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    vertical-align: middle;
  }
  &__icon-big {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    vertical-align: text-bottom;
  }

  @include respond-to("screen-md-min") {
    line-height: 2.5rem;

    &__text {
      font-size: 1.4rem;
    }
    &__icon {
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      vertical-align: middle;
    }
    &__icon-big {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
      vertical-align: text-bottom;
    }

    &__prefix-small {
      font-size: 1.2rem;
    }
    &__time {
      font-size: 1.4rem;
      margin-left: 0.2rem;
    }

    span {
      vertical-align: middle;
      display: inline-block;
    }
  }
}
