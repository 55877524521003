%ur-page {
  // Mobile setting
  width: 100%;
  &__content {
    box-sizing: border-box;
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }
  @include respond-to("screen-md-min") {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  @include respond-to("screen-lg-min") {
    max-width: 80%;
  }
  @include respond-to("screen-xl-min") {
    max-width: 85%;
  }
}

.ur-page {
  // Mobile setting
  width: 100%;

  &__content {
    box-sizing: border-box;
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }
  @include respond-to("screen-md-min") {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  @include respond-to("screen-lg-min") {
    max-width: 80%;
  }

  @include respond-to("screen-xl-min") {
    max-width: 85%;
  }
}

.ur-main-content {
  width: 100%;
  margin: auto;
  padding-bottom: $mobileBottonNavigationHeight;

  @include respond-to("screen-md-min") {
    padding-top: 0px;
    padding-bottom: 2rem;
  }
}
.ur-background-grey {
  background-color: $white;
}

.ur-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.ur-no-flex-wrap {
  flex-wrap: nowrap;
}

.ur-no-flex-wrap-desktop {
  @include respond-to("screen-md-min") {
    flex-wrap: nowrap;
  }
}
