/*
* ==== Breakpoints ==== 
*/

$screen-xxs-min: 360px;

/* Small devices (portrait tablets and large phones */
$screen-xs-min: 576px;

/* Medium devices (landscape tablets, 768px and up) */
$screen-sm-min: 768px;
$screen-sm-max: 991px;

/* Large devices (laptops/desktops, 992px and up) */
$screen-md-min: 992px;

/* Desktop devices (laptops/desktops, 1366px and up) */
$screen-lg-min: 1200px;

/* HD Desktop devices (laptops/desktops, 1600px and up) */
$screen-xl-min: 1600px;

$screen-xxl-min: 1920px;

$breakpoints: (
  "screen-xxs-min": (
    min-width: 360px
  ),
  "screen-xs-min": (
    min-width: 576px
  ),
  "screen-sm-min": (
    min-width: 768px
  ),
  "screen-sm-max": (
    max-width: 991px
  ),
  "screen-md-min": (
    min-width: 992px
  ),
  "screen-md-max": (
    min-width: 1199px
  ),
  "screen-lg-min": (
    min-width: 1200px
  ),
  "screen-lg-max": (
    min-width: 1599px
  ),
  "screen-xl-min": (
    min-width: 1600px
  ),
  "screen-xl-max": (
    min-width: 1919px
  ),
  "screen-xxl-min": (
    min-width: 1920px
  )
);

$maxDesktopWidth: 1600px;

/*
* ==== Colors ==== */
$urmart-color: #5c6ac4;
$morningshop-color: #ec6922;
$proteinshop-color: #2080f8;
$urmart-color-light: #b2b7dd;
$urmart-color-extra-light: #e6e8f4;
$urmart-color-heavy: #3745a0;
$background-color: #ddd;
$black: #464c51;
$grey-dark: #9fa9b2;
$grey-medium: #c4cdd5;
$grey-light: #f4f5fa;
$grey-lightest: #c4cdd5;
$white: #f5f8fa;
$pink: #ffc5c5;
$red: #cf4327;
$red-orange: #ed6347;
$yellow: #ffbb00;
$green: #4bc889;
$green-light: #b8e986;
$notification-text-color: #9fa9b2;

/**
* ==== Other Variables ==== */
$pageHeaderHeight: 55px;
$fixPageHeaderZindex: 2500;
$navigationZindex: 1000;
$mobileBottonNavigationHeight: 60px;

$zIndexManager: (
  "scrollTop": 2077,
  "mobileNav": 1000,
  "categorySubMenu": 6010,
  "couponList": 4677,
  "filterPopup": 5000,
  "fitlerBar": 2000,
  "loading": 6000,
  "messagePopup": 2000,
  "desktopNav": 3000,
  "productTabButton": 998,
  "reviewPopup": 3000,
  "searchBar": 6001,
  "wishlistButton": 2
);
