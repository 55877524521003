/**
* ==== Style Extenstion ==== */

@mixin main-text {
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: 0.2px;
  line-height: 2.7rem;
}
@mixin sub-text-big {
  font-weight: 500;
  font-size: 1.85rem;
  line-height: 2.4rem;
  letter-spacing: 0.2px;
}
@mixin sub-text-small {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.2px;
}
@mixin content-text-big {
  font-weight: 400;
  font-size: 1.2rem;
}

@mixin content-text-small {
  font-weight: 400;
  font-size: 1rem;
}

@mixin text-big {
  font-weight: 300;
  font-size: 0.85rem;
}

%text-small {
  font-weight: 400;
  font-size: 0.7rem;
}
