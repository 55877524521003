.ur-customer {
  width: 100%;

  &__main {
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
    @include respond-to("screen-md-min") {
      max-width: 70%;
      margin: auto;
    }
  }

  .ur-customer {
    &__facebook {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;
      @include respond-to("screen-sm-min") {
        padding-top: 3rem;
        padding-bottom: 2rem;
      }
    }

    &__divider {
      line-height: 1.875rem;
      position: relative;
      padding: 30px 0px 30px 0px;
      box-sizing: border-box;
      hr {
        color: #e6e8f4;
      }
      &__icon {
        position: absolute;
        top: 15px;
        left: calc(50% - 10px);
        width: 30px;
        height: 30px;
        border-radius: 50%;

        background-color: $grey-light;
        text-align: center;

        &:after {
          vertical-align: middle;
          content: "或";
          font-size: 0.75rem;
        }
      }
    }

    &__login {
    }
  }
}

.ur-customer-page {
  @extend %ur-page;
  .desktop-row {
    display: block;
  }
  .info-table {
    max-width: 90%;
    margin: auto;
    margin-top: 1rem;
    border: 1px solid #c4cdd5;
    border-radius: 3px;
    line-height: 2;

    &__header {
      border-bottom: 1px solid #c4cdd5;
      align-items: center;
      padding: 1rem 0;
      margin: 0 1.2rem;
    }

    &__row {
      border-bottom: 1px solid #c4cdd5;
      align-items: center;
      padding: 1rem 0;
      margin: 0 1rem;
    }
  }
  .info-table .info-table__row:last-child {
    border-bottom-color: transparent;
  }

  .hiddenDesktop {
    display: none;
  }

  .hiddenMobile {
    display: flex;
  }

  .border-row-desktop {
    border-width: 0px;
  }

  @include respond-to("screen-md-min") {
    .desktop-row {
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .info-table {
      border: none;
      color: #000000;
      &__header {
        padding: 1.2rem 1rem 0rem 1.2rem;
        margin: 0;
        color: #c7c7c7;
        font-size: 1.2rem;
        letter-spacing: 1px;
      }

      &__row {
        border: none;
        padding: 1rem;
        margin: 0;
      }
      &__header {
        border: none;
        color: #a7a7a7;
      }
    }
    .hiddenDesktop {
      display: flex;
    }
    .hiddenMobile {
      display: none;
    }
    .border-row-desktop {
      border-top: 1px solid #c4cdd5;
    }
  }
}
