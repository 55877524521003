.ur-wishlist-button {
  bottom: 1.75rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  z-index: 2;
  text-align: center;
  line-height: 0.8rem;

  &__added {
    height: 100%;
    width: 100%;
    background-image: url("../assets/heart_active_60.png");
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  &__unadded {
    height: 100%;
    width: 100%;
    background-image: url("../assets/heart_inactive_60.png");
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    &:hover {
      background-image: url("../assets/heart_hover_60.png");
      background-position: center;
      background-size: auto 100%;
      background-repeat: no-repeat;
    }
  }

  &__count {
    font-size: 0.8rem;
  }
}

.ur-wishlist-button__page {
  margin: 0;
  background-color: #ffffff;
  border: none;
  color: #333333;
  width: 100%;
  height: 100%;
  display: block;
  .ur-wishlist-button {
    position: relative;
    right: 0;
    bottom: 0;
  }
}
