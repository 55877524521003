/**
* Page header styles
*/
.ur-page-header {
  position: relative;
  color: $white;
  height: $pageHeaderHeight;
  z-index: $fixPageHeaderZindex;
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid $grey-light;
  background-color: $urmart-color;
  &__fixed {
    height: $pageHeaderHeight;
    width: 100%;
    background-color: $urmart-color;
    color: #ffffff;
  }

  &__icon {
    position: absolute;
    top: 0;
    padding: 0.5rem;
    width: 3rem;
    height: 100%;
    line-height: $pageHeaderHeight;
    box-sizing: border-box;
    color: #ffffff;

    cursor: pointer;
    img {
      width: 28px;
      height: 28px;
    }

    .material-icons {
      font-size: 1.8rem;
    }
  }

  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  &__title {
    display: inline-block;
    height: 100%;
    line-height: $pageHeaderHeight;
    color: $white;
    font-size: 1.57rem;
    font-weight: 700;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.ur-page-header-desktop {
  width: 100%;
  height: 65px;
  z-index: 3000;

  &__wrapper {
    height: 100%;
    margin: auto;
    max-width: $maxDesktopWidth;

    @include respond-to("screen-md-min") {
      width: 90%;
    }
  }

  &__logo {
    a {
      font-family: Nunito, "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif;
      color: $white;
      font-size: 1.6rem;
    }
    @include respond-to("screen-md-min") {
      a {
        font-size: 1.8rem;
      }
    }
  }

  &__icon {
    color: $white;
    position: relative;
    height: 100%;
    i {
      font-size: 2rem;
    }
    a {
      i {
        color: #ffffff;
      }
      &:hover {
        color: $white;
      }
    }
  }
  &__cartqty {
    background-color: $red;
    color: #ffffff;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    font-size: 0.8rem;
    text-align: center;
  }

  &__store {
    height: 100%;

    &-item {
      height: 3.5rem;
      line-height: 3.5rem;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      text-align: center;
      font-size: 0.8rem;
      letter-spacing: 0.2px;
      a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
      &__selected {
        height: 3.5rem;
        line-height: 3.5rem;
        border-bottom: 3px solid $white;
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        font-size: 0.8rem;

        a {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
    }

    @include respond-to("screen-lg-min") {
      &-item {
        font-size: 1rem;
        &__selected {
          font-size: 1rem;
        }
      }
    }
  }
}
