%searchText {
  border-radius: 10px;
  text-align: center;
  padding: 0.2rem;
  cursor: pointer;
}
.ur-search {
  &__section {
    padding: 4rem 0 1rem 0;
  }

  &__bar {
    &__wrapper {
      width: 100%;
      background-color: $urmart-color;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 6001;
      color: #ffffff;
      padding: 10px;

      @include respond-to("screen-md-min") {
        position: static;
      }
    }

    &__form {
      border: 1px solid #ededed;
      border-radius: 20px;
      height: 30px;
      background-color: #ffffff;
      text-align: left;
      margin-left: 10px;
      input {
        border: none;
        padding: 0;
        margin: 0;
        font-size: 16px;
        padding-left: 5px;
      }
    }
    &__icon {
      color: grey;
      padding-right: 5px;
      width: 100%;
      margin: 0;
    }
  }

  &__text {
    @extend %searchText;
    background-color: $urmart-color-light;
    margin: 10px;
    width: 100%;

    &__index {
      @extend %searchText;
      color: $white;
      width: auto;
      margin-left: 0.5rem;
    }
  }

  &__message {
    color: $grey-dark;
    text-align: center;
    width: 100%;
  }
}

.ur-search__wrapper__index {
  overflow: hidden;
  height: 2rem;
}
