.ur-text-level-1 {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.ur-text-level-2 {
  font-size: 1.62rem;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.ur-text-level-3 {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.ur-text-level-4 {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.ur-text-level-5 {
  font-size: 0.8125rem;
  font-weight: 300;
  letter-spacing: 0.2px;
}

.ur-text-level-6 {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.2px;
}
.ur-text-level-7 {
  font-size: 0.625rem;
  font-weight: 300;
  letter-spacing: 0.2px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.color-red {
  color: $red;
}
.color-white {
  color: #f5f8fa;
}
.color-grey {
  color: #4d4d4d;
}

.overflow-text {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  height: 3rem;
  line-height: 1.5rem;
  margin-top: 0.2rem;
  height: 4.5rem;
}
