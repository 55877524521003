.ur-qty-button {
  width: 24px;
  height: 24px;
  line-height: 18px;
  border-radius: 50%;
  background-color: $urmart-color;
  color: $white;
  text-align: center;
  user-select: none; /* Standard syntax */

  &__input {
    font-size: 22px;
    color: #5c6ac4;
    font-weight: 500;
    width: 100%;
    padding: 0;
    border: none;
    text-align: center;
  }
}
