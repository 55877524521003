/* The container */
.ur-radiobutton {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2rem;
  height: 2rem;

  vertical-align: -80%;
  display: inline-block;

  &__checkmark {
    height: 1rem;
    width: 1rem;
    background-color: #ffffff;
    border: 2px solid $urmart-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    &:after {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: white;
      content: "";
      display: none;
    }
  }

  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 1.2rem;
    height: 1.2rem;

    border: 1px solid $urmart-color;
    color: $urmart-color;
  }

  input:checked + .ur-radiobutton__checkmark::after {
    background: $urmart-color;
    display: block;
  }
}
