/**
* react-tabs styles
* https://github.com/reactjs/react-tabs
*/

.react-tabs {
  tap-highlight-color: none;
  width: 100%;

  &__tab-list {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    text-align: center;
  }

  &__tab {
    flex: 1 auto;
    border-bottom: 2px solid $grey-light;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1rem;
    cursor: pointer;
    width: 50%;
    font-size: 1rem;

    @include respond-to("screen-md-min") {
      font-size: 1rem;
      width: 20%;
    }

    &--selected {
      background-color: transparent;
      border-bottom: 3px solid $urmart-color;
      color: $urmart-color;
      font-size: bold;
    }

    &--disabled {
      color: $grey-light;
      cursor: default;
    }

    &:focus {
      outline: none;

      // &:after {
      //     content: "";
      //     position: absolute;
      //     height: 5px;
      //     left: -4px;
      //     right: -4px;
      //     bottom: -5px;
      //     background: #fff;
      // }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

.ur-couppin-tabs {
  @include respond-to("screen-md-min") {
    .react-tabs {
      &__tab {
        background-color: $grey-light;
        border-bottom: none;
        border-radius: 5px;
      }
    }
  }
}
