.ur-footer {
  padding: 1rem;
  background-color: #ffffff;

  @include respond-to("screen-md-min") {
    padding: 4rem 0px;
    box-sizing: border-box;
    width: 100%;
  }

  &__logo {
    font-family: "Nunito", "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    color: $urmart-color;
  }

  &__content {
    width: 100%;
    margin: auto;
    max-width: $maxDesktopWidth;

    @include respond-to("screen-md-min") {
      width: 80%;
    }
  }
  &__link {
    background-color: #e6e8f4;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    margin: auto;
    i {
      color: #b2b7dd;
      font-size: 2rem;
      vertical-align: middle;
      margin-top: -5px;
    }
    p {
      color: $black;
    }
  }
  &__link {
    &-active {
      color: $urmart-color;
    }
  }
}
