.ur-border-button {
  border: 2px solid $urmart-color;
  background-color: #ffffff;
  color: $urmart-color;
  padding: 5px;
  margin: 5px;
  max-width: 100px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include respond-to("screen-md-min") {
    max-width: 120px;
  }

  &.show {
    color: $white;
    background-color: $urmart-color;
  }
  &:disabled {
    opacity: 0.9;
  }
  a {
    display: inline-block;
    height: 100%;
    width: 100%;
    color: $urmart-color;
  }
}

.ur-checkout-button {
  border-radius: 3px;
  font-weight: 400;
  font-size: 1.14rem;
  display: inline-block;
  width: 46%;
  line-height: 24px;
  border: 2px solid $urmart-color;
  background-color: $urmart-color;
  color: $white;
  padding: 8px;
  text-align: center;

  &:hover {
    background-color: $urmart-color;
  }
}
