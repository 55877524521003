/**
*
*/
.order-summary {
  background-color: $white;
  padding: 10px 15px 10px 15px;
  margin: 10px 0px 10px 0px;
  &__title {
    border-bottom: 1px solid $grey-medium;
    font-weight: bold;
    font-size: 16px;
    padding: 15px 0px 15px 0px;
  }
  &__content {
    padding: 5px 0px 5px 0px;
  }
}
.ur-order-item {
  padding: 10px 0px 10px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid $grey-light;

  img {
    max-height: 200px;
  }
}
.ur-order-list {
  &__status {
    display: inline-block;
    min-width: 50px;
    text-align: center;
    padding: 5px;
    margin-right: 5px;
    border-radius: 5px;
  }
  &__item {
    padding: 1rem;
    @include respond-to("screen-md-min") {
      max-width: 80%;
      margin: auto;
      height: 100%;
      border-bottom: 1px solid $grey-light;
      padding: 1rem 0px;
    }
  }
}

.ur-order-cance-button {
  padding: 10px;
}

/**
* order shipping history
*/
.order-history {
  &__wrapper {
    padding: 10px;
    box-sizing: border-box;
  }
  &__item {
    height: 30px;
    vertical-align: middle;
    color: #AAAAAA;

    &__right {
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
    &__left {
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    &__line {
      display: inline-block;
      height: 30px;
      background-color: transparent;
      vertical-align: middle;
      position: relative;
      width: 10px;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 5px;
        height: 100%;
        border-right: 1px solid #fbeae5;
        // background-color: #FBEAE5;
        top: 0px;
        left: 0px;
        z-index: -1;
      }
    }
    &__dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      vertical-align: middle;
      border-radius: 50%;
      background-color: #ed6347;
    }
    &:nth-of-type(1) {
      color: #FF4500;
    }
  }
}
