/*! 
Default styling to HTML
Use element descendent selector, or a child selector here, along with any pseudo-classes.
*/

*:not(ol) {
  padding: 0;
  margin: 0;
  user-select: text;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  // base font size for rem
  font-size: 14px;
  width: 100%;

  @include respond-to("screen-md-min") {
    font-size: 14px;
  }
}

body {
  background-color: #ffffff;
  font-family: "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  color: $black;
  // only for Safari, let scrolling be smooth
  -webkit-overflow-scrolling: touch;
}

a {
  color: $black;
  text-decoration: none;

  @include respond-to("screen-md-min") {
    &:hover {
      text-decoration: none;
      outline: 0;
    }
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}

button {
  border-radius: 3px;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.14rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  border: 2px solid $urmart-color;
  background-color: $urmart-color;
  color: $white;
  width: 50%;
  cursor: pointer;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  &:focus {
    // background-color: $urmart-color-heavy;
    // border: 2px solid transparent;
    outline: 0;
  }

  a {
    display: inline-block;
    height: 100%;
    width: 100%;
    &:active {
      background-color: transparent;
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: initial;
  }
}

input {
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  font-size: 1.15rem;
  line-height: 24px;
  letter-spacing: 0.2px;
  border-radius: 5px;
  border: 1px solid $grey-light;
  background-color: transparent;

  &[type="radio"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  &:disabled {
    border: 1px solid $grey-medium;
    opacity: 0.7;
    background-color: transparent;
  }

  &:focus {
    outline: none;
    background-color: transparent;
  }

  &:autofill {
    background-color: transparent;
    &:focus,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
}
label {
  line-height: 2rem;
}
select {
  border: 2px solid $urmart-color;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0px 5px 0px;
  height: 50px;
  font-size: 16px;
  background-color: #ffffff;
  appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

textarea {
  padding: 5px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid $urmart-color;
  resize: none;
  box-sizing: border-box;
  &:disabled {
    border: 1px solid $grey-medium;
    opacity: 0.7;
  }

  &:autofill {
    background-color: transparent;
    &:focus {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
  }
}

ul {
  list-style-type: none;
}
