@charset "UTF-8";
/*!
 * Main App Scss
*/
@import-normalize;
/*
* ==== Breakpoints ==== 
*/
/* Small devices (portrait tablets and large phones */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Desktop devices (laptops/desktops, 1366px and up) */
/* HD Desktop devices (laptops/desktops, 1600px and up) */
/*
* ==== Colors ==== */
/**
* ==== Other Variables ==== */
/*! 
Default styling to HTML
Use element descendent selector, or a child selector here, along with any pseudo-classes.
*/
*:not(ol) {
  padding: 0;
  margin: 0;
  user-select: text;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html {
  font-size: 14px;
  width: 100%; }
  @media (min-width: 992px) {
    html {
      font-size: 14px; } }

body {
  background-color: #ffffff;
  font-family: "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #464c51;
  -webkit-overflow-scrolling: touch; }

a {
  color: #464c51;
  text-decoration: none; }
  @media (min-width: 992px) {
    a:hover {
      text-decoration: none;
      outline: 0; } }
  a:active {
    background-color: transparent;
    border: none;
    outline: 0; }

img {
  max-width: 100%;
  height: auto; }

button {
  border-radius: 3px;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.14rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  border: 2px solid #5c6ac4;
  background-color: #5c6ac4;
  color: #f5f8fa;
  width: 50%;
  cursor: pointer;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }
  button:focus {
    outline: 0; }
  button a {
    display: inline-block;
    height: 100%;
    width: 100%; }
    button a:active {
      background-color: transparent; }
  button:disabled {
    opacity: 0.7;
    cursor: initial; }

input {
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  font-size: 1.15rem;
  line-height: 24px;
  letter-spacing: 0.2px;
  border-radius: 5px;
  border: 1px solid #f4f5fa;
  background-color: transparent; }
  input[type="radio"] {
    width: 20px;
    height: 20px;
    cursor: pointer; }
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    appearance: none; }
  input:disabled {
    border: 1px solid #c4cdd5;
    opacity: 0.7;
    background-color: transparent; }
  input:focus {
    outline: none;
    background-color: transparent; }
  input:autofill {
    background-color: transparent; }
    input:autofill:focus, input:autofill:active, input:autofill:focus {
      background-color: transparent; }

label {
  line-height: 2rem; }

select {
  border: 2px solid #5c6ac4;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0px 5px 0px;
  height: 50px;
  font-size: 16px;
  background-color: #ffffff;
  appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ""; }

textarea {
  padding: 5px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid #5c6ac4;
  resize: none;
  box-sizing: border-box; }
  textarea:disabled {
    border: 1px solid #c4cdd5;
    opacity: 0.7; }
  textarea:autofill {
    background-color: transparent; }
    textarea:autofill:focus {
      background-color: transparent; }
    textarea:autofill:active {
      background-color: transparent; }

ul {
  list-style-type: none; }

/*!
 * Place major components here!
 * The major components are those which define the structure of the page and generally do not repeat itself in the page,
 * Like headers, footers, sidebar, body, etc.
 * These major components form the page Layout and hence, referred to as Layout rules.
 * You should not change the file here unless the whole design are change
*/
/*! 
 * FlexboxGrid lib
 * http://flexboxgrid.com/
*/
/* Uncomment and set these variables to customize the grid. */
.container-fluid {
  margin-right: auto;
  margin-left: auto; }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap; }

.row.reverse {
  flex-direction: row-reverse; }

.col-row {
  flex: 1; }

.col.reverse {
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2-4,
.col-xs-2-3,
.col-xs-6-5 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  flex-basis: 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  flex-basis: 16.667%;
  max-width: 16.667%; }

.col-xs-2-3 {
  flex-basis: 21%;
  max-width: 21%; }

.col-xs-2-4 {
  flex-basis: 20%;
  max-width: 20%; }

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  flex-basis: 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  flex-basis: 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-6-5 {
  flex-basis: 42%;
  max-width: 42%; }

.col-xs-7 {
  flex-basis: 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  flex-basis: 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  flex-basis: 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  flex-basis: 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-12-no-padding {
  flex-basis: 100%;
  max-width: 100%;
  padding: 0px; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  justify-content: center;
  text-align: center; }

.end-xs {
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  justify-content: space-around; }

.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media (min-width: 768px) {
  .container {
    width: 46rem; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 5px;
    padding-left: 5px; }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    margin-left: 8.333%; }
  .col-sm-offset-2 {
    margin-left: 16.667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.333%; }
  .col-sm-offset-5 {
    margin-left: 41.667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.333%; }
  .col-sm-offset-8 {
    margin-left: 66.667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.333%; }
  .col-sm-offset-11 {
    margin-left: 91.667%; }
  .start-sm {
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  .end-sm {
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    justify-content: space-around; }
  .between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media (min-width: 992px) {
  .container {
    width: 61rem; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 5px;
    padding-left: 5px; }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    margin-left: 8.333%; }
  .col-md-offset-2 {
    margin-left: 16.667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.333%; }
  .col-md-offset-5 {
    margin-left: 41.667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.333%; }
  .col-md-offset-8 {
    margin-left: 66.667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.333%; }
  .col-md-offset-11 {
    margin-left: 91.667%; }
  .start-md {
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    justify-content: center;
    text-align: center; }
  .end-md {
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    justify-content: space-around; }
  .between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media (min-width: 1200px) {
  .container {
    width: 71rem; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 5px;
    padding-left: 5px; }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    margin-left: 8.333%; }
  .col-lg-offset-2 {
    margin-left: 16.667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.333%; }
  .col-lg-offset-5 {
    margin-left: 41.667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.333%; }
  .col-lg-offset-8 {
    margin-left: 66.667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.333%; }
  .col-lg-offset-11 {
    margin-left: 91.667%; }
  .start-lg {
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  .end-lg {
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    justify-content: space-around; }
  .between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

/**
* Page header styles
*/
.ur-page-header {
  position: relative;
  color: #f5f8fa;
  height: 55px;
  z-index: 2500;
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid #f4f5fa;
  background-color: #5c6ac4; }
  .ur-page-header__fixed {
    height: 55px;
    width: 100%;
    background-color: #5c6ac4;
    color: #ffffff; }
  .ur-page-header__icon {
    position: absolute;
    top: 0;
    padding: 0.5rem;
    width: 3rem;
    height: 100%;
    line-height: 55px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer; }
    .ur-page-header__icon img {
      width: 28px;
      height: 28px; }
    .ur-page-header__icon .material-icons {
      font-size: 1.8rem; }
  .ur-page-header .left {
    left: 0; }
  .ur-page-header .right {
    right: 0; }
  .ur-page-header__title {
    display: inline-block;
    height: 100%;
    line-height: 55px;
    color: #f5f8fa;
    font-size: 1.57rem;
    font-weight: 700;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.ur-page-header-desktop {
  width: 100%;
  height: 65px;
  z-index: 3000; }
  .ur-page-header-desktop__wrapper {
    height: 100%;
    margin: auto;
    max-width: 1600px; }
    @media (min-width: 992px) {
      .ur-page-header-desktop__wrapper {
        width: 90%; } }
  .ur-page-header-desktop__logo a {
    font-family: Nunito, "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif;
    color: #f5f8fa;
    font-size: 1.6rem; }
  @media (min-width: 992px) {
    .ur-page-header-desktop__logo a {
      font-size: 1.8rem; } }
  .ur-page-header-desktop__icon {
    color: #f5f8fa;
    position: relative;
    height: 100%; }
    .ur-page-header-desktop__icon i {
      font-size: 2rem; }
    .ur-page-header-desktop__icon a i {
      color: #ffffff; }
    .ur-page-header-desktop__icon a:hover {
      color: #f5f8fa; }
  .ur-page-header-desktop__cartqty {
    background-color: #cf4327;
    color: #ffffff;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    font-size: 0.8rem;
    text-align: center; }
  .ur-page-header-desktop__store {
    height: 100%; }
    .ur-page-header-desktop__store-item {
      height: 3.5rem;
      line-height: 3.5rem;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      text-align: center;
      font-size: 0.8rem;
      letter-spacing: 0.2px; }
      .ur-page-header-desktop__store-item a {
        color: #f5f8fa; }
        .ur-page-header-desktop__store-item a:hover {
          color: #f5f8fa; }
      .ur-page-header-desktop__store-item__selected {
        height: 3.5rem;
        line-height: 3.5rem;
        border-bottom: 3px solid #f5f8fa;
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        font-size: 0.8rem; }
        .ur-page-header-desktop__store-item__selected a {
          color: #f5f8fa; }
          .ur-page-header-desktop__store-item__selected a:hover {
            color: #f5f8fa; }
    @media (min-width: 1200px) {
      .ur-page-header-desktop__store-item {
        font-size: 1rem; }
        .ur-page-header-desktop__store-item__selected {
          font-size: 1rem; } }

.ur-footer {
  padding: 1rem;
  background-color: #ffffff; }
  @media (min-width: 992px) {
    .ur-footer {
      padding: 4rem 0px;
      box-sizing: border-box;
      width: 100%; } }
  .ur-footer__logo {
    font-family: "Nunito", "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    color: #5c6ac4; }
  .ur-footer__content {
    width: 100%;
    margin: auto;
    max-width: 1600px; }
    @media (min-width: 992px) {
      .ur-footer__content {
        width: 80%; } }
  .ur-footer__link {
    background-color: #e6e8f4;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    margin: auto; }
    .ur-footer__link i {
      color: #b2b7dd;
      font-size: 2rem;
      vertical-align: middle;
      margin-top: -5px; }
    .ur-footer__link p {
      color: #464c51; }
  .ur-footer__link-active {
    color: #5c6ac4; }

/**
* Five page nav items
*/
.ur-tabnav {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom); }
  .ur-tabnav__nav {
    display: flex;
    margin: 0;
    padding: 10px;
    border-top: 1px solid #dce5ed;
    box-sizing: border-box; }
  .ur-tabnav__nav-item {
    height: 100%;
    flex: 1;
    text-align: center; }
    .ur-tabnav__nav-item a {
      display: block; }
  .ur-tabnav__selected {
    color: #5c6ac4; }
  .ur-tabnav__icon {
    font-size: 1.8rem; }
  .ur-tabnav__icon-title {
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center; }

/**
* Store tab setting
*/
.ur-store-tab {
  height: 45px;
  display: flex;
  font-size: 12px;
  width: 100%;
  background-color: #ffffff; }
  @media (min-width: 992px) {
    .ur-store-tab {
      display: none; } }

/*
* Dynamic create *-tab class with theme color
*/
.urmart-tab {
  color: #5c6ac4;
  border-color: #5c6ac4;
  border-bottom: 3px solid #5c6ac4;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0px; }
  .urmart-tab > .ur-store-tab__tab-link {
    color: #5c6ac4;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    font-size: 13px;
    width: 100%; }

.urmart-tab-unselected {
  color: #5c6ac4;
  border-color: #5c6ac4;
  border-bottom: 3px solid transparent;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #f0f1fb; }
  .urmart-tab-unselected > .ur-store-tab__tab-link {
    color: #5c6ac4;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    line-height: 45px;
    font-size: 13px;
    width: 100%; }

.morningshop-tab {
  color: #ec6922;
  border-color: #ec6922;
  border-bottom: 3px solid #ec6922;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0px; }
  .morningshop-tab > .ur-store-tab__tab-link {
    color: #ec6922;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    font-size: 13px;
    width: 100%; }

.morningshop-tab-unselected {
  color: #ec6922;
  border-color: #ec6922;
  border-bottom: 3px solid transparent;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #f0f1fb; }
  .morningshop-tab-unselected > .ur-store-tab__tab-link {
    color: #ec6922;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    line-height: 45px;
    font-size: 13px;
    width: 100%; }

.proteinshop-tab {
  color: #2080f8;
  border-color: #2080f8;
  border-bottom: 3px solid #2080f8;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0px; }
  .proteinshop-tab > .ur-store-tab__tab-link {
    color: #2080f8;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    font-size: 13px;
    width: 100%; }

.proteinshop-tab-unselected {
  color: #2080f8;
  border-color: #2080f8;
  border-bottom: 3px solid transparent;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #f0f1fb; }
  .proteinshop-tab-unselected > .ur-store-tab__tab-link {
    color: #2080f8;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    line-height: 45px;
    font-size: 13px;
    width: 100%; }

.ur-customer-page {
  width: 100%; }
  @media (min-width: 992px) {
    .ur-customer-page {
      max-width: 90%;
      margin-right: auto;
      margin-left: auto; } }
  @media (min-width: 1200px) {
    .ur-customer-page {
      max-width: 80%; } }
  @media (min-width: 1600px) {
    .ur-customer-page {
      max-width: 85%; } }

.ur-page {
  width: 100%; }
  .ur-page__content {
    box-sizing: border-box;
    padding-right: 1.8rem;
    padding-left: 1.8rem; }
  @media (min-width: 992px) {
    .ur-page {
      max-width: 90%;
      margin-right: auto;
      margin-left: auto; } }
  @media (min-width: 1200px) {
    .ur-page {
      max-width: 80%; } }
  @media (min-width: 1600px) {
    .ur-page {
      max-width: 85%; } }

.ur-main-content {
  width: 100%;
  margin: auto;
  padding-bottom: 60px; }
  @media (min-width: 992px) {
    .ur-main-content {
      padding-top: 0px;
      padding-bottom: 2rem; } }

.ur-background-grey {
  background-color: #f5f8fa; }

.ur-no-padding {
  padding-left: 0;
  padding-right: 0; }

.ur-no-flex-wrap {
  flex-wrap: nowrap; }

@media (min-width: 992px) {
  .ur-no-flex-wrap-desktop {
    flex-wrap: nowrap; } }

.ur-homepage {
  width: 100%;
  background-color: #e6e8f4; }
  @media (min-width: 992px) {
    .ur-homepage {
      padding-top: 2rem;
      padding-bottom: 2rem; } }
  .ur-homepage__top {
    width: 100%;
    margin: auto;
    max-width: 1600px; }
    @media (min-width: 992px) {
      .ur-homepage__top {
        width: 90%; }
        .ur-homepage__top__slider {
          margin-left: 1rem; } }
  .ur-homepage__wrapper {
    width: 100%;
    padding-bottom: 1.5rem; }
    .ur-homepage__wrapper:nth-of-type(2n + 1) {
      background-color: #f5f8fa; }
    .ur-homepage__wrapper:nth-of-type(2n) {
      background-color: #ffffff; }
  .ur-homepage__section {
    width: 100%;
    margin: auto;
    max-width: 1600px; }
    @media (min-width: 992px) {
      .ur-homepage__section {
        width: 80%; } }
  .ur-homepage__carousel {
    height: 200px; }
    @media (min-width: 992px) {
      .ur-homepage__carousel {
        height: 500px; } }
    @media (min-width: 992px) {
      .ur-homepage__carousel {
        height: auto;
        max-height: 576px; } }
  .ur-homepage__influid {
    width: 100%;
    margin: auto;
    max-width: 1600px; }
    @media (min-width: 992px) {
      .ur-homepage__influid {
        width: 80%; } }
  .ur-homepage__pointer {
    cursor: pointer; }

.ur-customer {
  width: 100%; }
  .ur-customer__main {
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box; }
    @media (min-width: 992px) {
      .ur-customer__main {
        max-width: 70%;
        margin: auto; } }
  .ur-customer .ur-customer__facebook {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
    @media (min-width: 768px) {
      .ur-customer .ur-customer__facebook {
        padding-top: 3rem;
        padding-bottom: 2rem; } }
  .ur-customer .ur-customer__divider {
    line-height: 1.875rem;
    position: relative;
    padding: 30px 0px 30px 0px;
    box-sizing: border-box; }
    .ur-customer .ur-customer__divider hr {
      color: #e6e8f4; }
    .ur-customer .ur-customer__divider__icon {
      position: absolute;
      top: 15px;
      left: calc(50% - 10px);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #f4f5fa;
      text-align: center; }
      .ur-customer .ur-customer__divider__icon:after {
        vertical-align: middle;
        content: "或";
        font-size: 0.75rem; }

.ur-customer-page .desktop-row {
  display: block; }

.ur-customer-page .info-table {
  max-width: 90%;
  margin: auto;
  margin-top: 1rem;
  border: 1px solid #c4cdd5;
  border-radius: 3px;
  line-height: 2; }
  .ur-customer-page .info-table__header {
    border-bottom: 1px solid #c4cdd5;
    align-items: center;
    padding: 1rem 0;
    margin: 0 1.2rem; }
  .ur-customer-page .info-table__row {
    border-bottom: 1px solid #c4cdd5;
    align-items: center;
    padding: 1rem 0;
    margin: 0 1rem; }

.ur-customer-page .info-table .info-table__row:last-child {
  border-bottom-color: transparent; }

.ur-customer-page .hiddenDesktop {
  display: none; }

.ur-customer-page .hiddenMobile {
  display: flex; }

.ur-customer-page .border-row-desktop {
  border-width: 0px; }

@media (min-width: 992px) {
  .ur-customer-page .desktop-row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap; }
  .ur-customer-page .info-table {
    border: none;
    color: #000000; }
    .ur-customer-page .info-table__header {
      padding: 1.2rem 1rem 0rem 1.2rem;
      margin: 0;
      color: #c7c7c7;
      font-size: 1.2rem;
      letter-spacing: 1px; }
    .ur-customer-page .info-table__row {
      border: none;
      padding: 1rem;
      margin: 0; }
    .ur-customer-page .info-table__header {
      border: none;
      color: #a7a7a7; }
  .ur-customer-page .hiddenDesktop {
    display: flex; }
  .ur-customer-page .hiddenMobile {
    display: none; }
  .ur-customer-page .border-row-desktop {
    border-top: 1px solid #c4cdd5; } }

.ur-product-page__content {
  width: 100%;
  margin: auto;
  max-width: 1600px;
  padding-bottom: 55px; }

.ur-product-page__title {
  padding: 0.8rem 0.5rem;
  font-size: 1.2rem; }

.ur-product-page__reviews {
  padding: 5px; }

.ur-product-page__left {
  background-color: #fff;
  position: relative; }
  .ur-product-page__left img {
    height: 100%; }

.ur-product-page__right {
  background-color: #fff; }

@media (min-width: 992px) {
  .ur-product-page__content {
    width: 90%; }
  .ur-product-page__title {
    font-size: 1.8rem;
    padding: 0.8rem 0;
    font-size: 1.5rem; }
  .ur-product-page__left {
    padding: 10px;
    overflow: hidden;
    margin-top: 10px; }
  .ur-product-page__right {
    padding: 20px 10px 20px 10px;
    overflow: hidden; } }

.width-100 {
  width: 100%; }

.ur-d-block {
  display: block; }

.ur-search__text, .ur-search__text__index {
  border-radius: 10px;
  text-align: center;
  padding: 0.2rem;
  cursor: pointer; }

.ur-search__section {
  padding: 4rem 0 1rem 0; }

.ur-search__bar__wrapper {
  width: 100%;
  background-color: #5c6ac4;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6001;
  color: #ffffff;
  padding: 10px; }
  @media (min-width: 992px) {
    .ur-search__bar__wrapper {
      position: static; } }

.ur-search__bar__form {
  border: 1px solid #ededed;
  border-radius: 20px;
  height: 30px;
  background-color: #ffffff;
  text-align: left;
  margin-left: 10px; }
  .ur-search__bar__form input {
    border: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    padding-left: 5px; }

.ur-search__bar__icon {
  color: grey;
  padding-right: 5px;
  width: 100%;
  margin: 0; }

.ur-search__text {
  background-color: #b2b7dd;
  margin: 10px;
  width: 100%; }
  .ur-search__text__index {
    color: #f5f8fa;
    width: auto;
    margin-left: 0.5rem; }

.ur-search__message {
  color: #9fa9b2;
  text-align: center;
  width: 100%; }

.ur-search__wrapper__index {
  overflow: hidden;
  height: 2rem; }

/*
Form
*/
.ur-form-control__group {
  position: relative;
  margin: 10px 8px 10px 0px;
  line-height: 1rem;
  padding: 5px 0px 5px 0px;
  /* When the radio button is checked, add a blue background */ }
  .ur-form-control__group label {
    color: #464c51; }
  .ur-form-control__group textarea {
    margin: 5px 0 5px 0;
    width: 100%;
    background-color: #ffffff; }
  .ur-form-control__group select {
    width: 100%; }
  .ur-form-control__group input[type="text"],
  .ur-form-control__group input[type="email"],
  .ur-form-control__group input[type="password"],
  .ur-form-control__group input[type="number"],
  .ur-form-control__group input[type="tel"] {
    width: 100%; }
  .ur-form-control__group input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
  .ur-form-control__group input[type="radio"]:checked ~ .ur-form-control__checkmark {
    background-color: #5c6ac4; }
  .ur-form-control__group__required {
    color: #cf4327;
    line-height: 1.1rem; }

.ur-form-control__message {
  color: red; }

.ur-form-control__button {
  text-align: right;
  margin-top: 0.5rem; }
  .ur-form-control__button button {
    white-space: normal;
    font-size: 1.14rem;
    height: 3rem;
    line-height: 3rem; }
  .ur-form-control__button a {
    color: #f5f8fa; }

.ur-form-control__checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #e8e8e8;
  border-radius: 50%;
  cursor: pointer; }

.ur-form-control__text {
  display: inline-block;
  margin-left: 22px; }

.ur-register-form__group {
  margin-top: 1rem; }

.ur-register-form__required {
  font-size: 0.9rem;
  margin-left: 5px;
  color: #cf4327; }

.ur-register-form input {
  width: 100%; }

.ur-register-form button {
  margin-top: 0;
  margin-bottom: 0;
  height: 40px; }

.ur-register-form label div {
  margin-top: 0.6rem; }

.ur-register-form__error {
  color: #cf4327;
  font-size: 1rem;
  margin-top: 0.5rem; }
  .ur-register-form__error i {
    vertical-align: -25%;
    font-size: 1.5rem;
    margin-right: 2px; }

.ur-register-form__notification {
  color: #9fa9b2;
  font-size: 0.9rem;
  margin-left: 5px; }

.ur-form-eye {
  width: 1.75rem;
  height: 1.75rem; }
  @media (min-width: 992px) {
    .ur-form-eye {
      width: 2rem;
      height: 2rem; } }

/**
* Facebook Login button
*/
.ur-facebook {
  background-color: #3a5998;
  border-color: #3a5998;
  width: 100%;
  color: #f5f8fa;
  height: 3rem;
  line-height: 3rem;
  font-size: 1rem; }
  .ur-facebook__icon {
    width: 20px;
    height: 20px; }

#urfblogin:hover {
  background-color: #3a5998;
  border-color: #3a5998; }

.ur-qty-button {
  width: 24px;
  height: 24px;
  line-height: 18px;
  border-radius: 50%;
  background-color: #5c6ac4;
  color: #f5f8fa;
  text-align: center;
  user-select: none;
  /* Standard syntax */ }
  .ur-qty-button__input {
    font-size: 22px;
    color: #5c6ac4;
    font-weight: 500;
    width: 100%;
    padding: 0;
    border: none;
    text-align: center; }

.ur-product-price {
  white-space: nowrap; }
  .ur-product-price__page {
    margin: 0.3rem; }
  .ur-product-price__viphide {
    color: #cf4327;
    font-size: 1.2rem; }
  .ur-product-price__old {
    color: #a6a6a6;
    font-size: 0.8rem; }
    .ur-product-price__old__page {
      color: #a6a6a6; }
  .ur-product-price__new {
    font-size: 1.2rem;
    color: #ed6347;
    margin-right: 5px; }
    .ur-product-price__new__page {
      color: #ed6347;
      margin: 0.3rem;
      font-size: 1.5rem; }
  .ur-product-price__discount {
    background-color: #ed6347;
    color: #f5f8fa;
    font-size: 1rem;
    margin-left: 0.5rem;
    line-height: 1.2rem;
    padding: 0 0.5rem; }
  @media (min-width: 360px) {
    .ur-product-price__viphide {
      color: #cf4327;
      font-size: 1.5rem; }
    .ur-product-price__old {
      color: #a6a6a6;
      font-size: 1rem; }
      .ur-product-price__old__page {
        color: #a6a6a6; }
    .ur-product-price__new {
      font-size: 1.5rem;
      color: #ed6347;
      margin-right: 5px; }
      .ur-product-price__new__page {
        color: #ed6347;
        margin-right: 5px;
        font-size: 1.5rem; }
    .ur-product-price__discount {
      background-color: #ed6347;
      color: #f5f8fa;
      font-size: 1rem;
      margin-left: 0.5rem;
      line-height: 1.2rem;
      padding: 0 0.5rem; } }
  @media (min-width: 992px) {
    .ur-product-price__old__page {
      font-size: 1.5rem; }
    .ur-product-price__new__page {
      margin-right: 5px;
      font-size: 2rem; }
    .ur-product-price__discount {
      line-height: 1.5rem; } }
  @media (min-width: 1600px) {
    .ur-product-price__old {
      font-size: 1.1rem; }
    .ur-product-price__new {
      font-size: 1.6rem; } }

.ur-message-popup {
  position: fixed;
  width: 200px;
  max-height: 200px;
  top: calc(50% - 100px);
  right: calc(50% - 100px);
  border-radius: 10px;
  background-color: #464c51;
  color: #f5f8fa;
  z-index: 2000;
  padding: 10px;
  text-align: center; }
  .ur-message-popup__close {
    position: absolute;
    top: 5px;
    right: 5px; }
  .ur-message-popup__content__icon {
    display: "block"; }
    .ur-message-popup__content__icon__top {
      color: "green";
      font-size: 24px; }
    .ur-message-popup__content__icon__bottom {
      color: "white";
      font-size: 48px; }

/**
* Message styls for notification
*/
.ur-message {
  padding: 1.2rem; }
  .ur-message__text__warning {
    color: #cf4327; }
  .ur-message__text__notification {
    color: #cf4327; }
  .ur-message__notification {
    color: #ed6347; }
  .ur-message__img {
    padding: 10px;
    vertical-align: middle;
    max-width: 200px; }

.ur-error-message {
  color: #cf4327; }

.ur-product-list {
  overflow: hidden; }
  .ur-product-list__item {
    position: relative;
    background-color: #fff;
    margin: 10px 0px 10px 0px;
    border: 1px solid #f4f5fa;
    border-radius: 5px;
    height: 10rem;
    box-sizing: border-box;
    padding: 5px 0px 5px 0px; }
    .ur-product-list__item__price {
      color: #ed6347;
      font-size: 1rem; }
    .ur-product-list__item__qty {
      color: #ababab;
      font-size: 1rem; }
    .ur-product-list__item__img {
      padding: 5px;
      height: 100%;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      text-align: center;
      max-height: 150px; }
      .ur-product-list__item__img img {
        width: auto;
        max-width: 100%;
        max-height: 100%; }
    .ur-product-list__item__name {
      font-size: 1rem;
      box-sizing: border-box;
      height: 6rem; }
    @media (min-width: 992px) {
      .ur-product-list__item__img {
        max-height: 200px; }
      .ur-product-list__item__name {
        font-size: 1.2rem; }
      .ur-product-list__item__price {
        font-size: 1.3rem; } }
  .ur-product-list__qty {
    text-align: center;
    font-size: 20px;
    color: #5c6ac4; }
  .ur-product-list__button {
    width: 40px;
    height: 40px;
    color: #5c6ac4;
    font-size: 18px;
    border: none; }
    .ur-product-list__button__circle {
      width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #5c6ac4;
      text-align: center; }
  .ur-product-list__remove {
    position: absolute;
    top: 5px;
    right: 5px;
    text-align: center; }
  .ur-product-list__discount {
    position: absolute;
    top: 8px;
    padding: 3px 13px 3px 10px;
    background-color: #ed6347;
    color: #f4f5fa;
    font-size: 12px;
    letter-spacing: 0.2px; }

.ur-horizantal-scroll-wrapper__arrow-right, .ur-horizantal-scroll-wrapper__arrow-left {
  display: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  top: 45%;
  position: absolute;
  z-index: 1000;
  border: 1px solid #f4f5fa;
  box-shadow: 0 0 5px #c4cdd5;
  background-color: #f5f8fa;
  color: #464c51; }
  .ur-horizantal-scroll-wrapper__arrow-right i, .ur-horizantal-scroll-wrapper__arrow-left i {
    vertical-align: middle; }
  .ur-horizantal-scroll-wrapper__arrow-right:hover, .ur-horizantal-scroll-wrapper__arrow-left:hover {
    width: 2.5rem;
    height: 2.5rem;
    transition: width 0.3s, height 0.3s, line-height 0.3s; }

.ur-horizantal-scroll-wrapper {
  position: relative; }
  .ur-horizantal-scroll-wrapper__content {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    @media (min-width: 992px) {
      .ur-horizantal-scroll-wrapper__content {
        overflow-x: hidden; } }
  .ur-horizantal-scroll-wrapper__arrow-right {
    right: 0; }
  .ur-horizantal-scroll-wrapper__arrow-left {
    left: 0; }

.ur-productpage-button {
  border: 2px solid #b2b7dd;
  border-radius: 5px;
  height: 2.2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  position: relative; }
  .ur-productpage-button i {
    display: inline-block;
    vertical-align: middle; }
  .ur-productpage-button span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3rem;
    color: #9097ce;
    font-size: 0.8rem;
    letter-spacing: 0.5px; }

.ur-yellow {
  font-size: 24px;
  color: #ffbb00; }

.ur-productpage-tab {
  position: fixed;
  bottom: 0px;
  z-index: 998;
  background-color: #fff;
  width: 100%; }
  @media (min-width: 992px) {
    .ur-productpage-tab {
      display: none; } }
  .ur-productpage-tab__fake {
    width: 100%;
    height: 60px;
    background-color: #fff;
    padding-bottom: env(safe-area-inset-bottom); }
  .ur-productpage-tab__wrapper {
    margin: 0;
    border-top: 1px solid #dce5ed;
    box-sizing: border-box;
    padding: 10px;
    height: 55px;
    max-width: 1600px; }
  .ur-productpage-tab__item {
    text-align: center; }

.ur-description {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding-bottom: 3rem;
  font-size: 1.2rem;
  color: #464c51;
  padding: 2rem 1rem; }
  .ur-description img,
  .ur-description iframe {
    width: 100%;
    max-width: 800px;
    margin: auto; }
  .ur-description p,
  .ur-description div {
    font-size: 1.2rem;
    padding: 0.5rem;
    color: #464c51;
    max-width: 800px;
    margin: auto; }
  .ur-description ul li {
    padding: 0.5rem;
    color: #464c51;
    font-size: 1.2rem; }
  @media (min-width: 1600px) {
    .ur-description {
      max-width: 1600px; } }

.ur-group-product-list {
  flex-wrap: nowrap;
  padding: 1rem; }
  .ur-group-product-list hr {
    color: #f4f5fa;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .ur-group-product-list__title {
    font-size: 1.2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-top: 1px solid #f4f5fa;
    border-bottom: 1px solid #f4f5fa; }
  .ur-group-product-list__content {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #f4f5fa; }
  .ur-group-product-list__item__name {
    margin-bottom: 0.2rem; }
  .ur-group-product-list__item__detail {
    height: 3rem; }
  .ur-group-product-list__item__price {
    font-size: 1.5rem;
    height: 2rem;
    color: #cf4327; }
  .ur-group-product-list__item__show {
    height: 100%;
    display: flex;
    position: relative; }
  .ur-group-product-list__item__hide {
    display: none; }
  .ur-group-product-list__item__date {
    background-color: #b0b7d6;
    padding: 0.3rem; }
    .ur-group-product-list__item__date p {
      background-color: #bbc2dc;
      color: #000000;
      padding: 0.3rem;
      font-size: 0.9rem; }
  .ur-group-product-list__item__img img {
    width: 100%;
    max-width: 8rem; }
  .ur-group-product-list__qty {
    text-align: center;
    font-size: 20px;
    color: #5c6ac4; }
  .ur-group-product-list__options {
    padding-bottom: 0.8rem;
    padding-top: 0.8rem; }
  @media screen and (min-width: 992px) {
    .ur-group-product-list__item__name {
      font-size: 1.2rem;
      margin-bottom: 0.2rem; }
    .ur-group-product-list__item__detail {
      height: 3rem; }
    .ur-group-product-list__item__price {
      font-size: 1.5rem;
      height: 2rem;
      color: #cf4327; }
    .ur-group-product-list__item__show {
      height: 100%;
      display: flex;
      position: relative; }
    .ur-group-product-list__item__hide {
      display: none; } }

.ur-product-slider {
  height: 400px;
  overflow: hidden;
  text-align: center; }
  .ur-product-slider .carousel .slide img {
    object-fit: contain;
    height: 100%; }
  .ur-product-slider .carousel .control-dots {
    position: absolute; }
  @media (min-width: 992px) {
    .ur-product-slider {
      height: 576px; } }

.ur-product-button {
  margin: 0.8rem 0; }

.ur-discount-label {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #ed6347;
  color: #f5f8fa;
  font-size: 1.2rem;
  padding: 3px 13px 3px 10px; }

.ur-discount-timer {
  background-color: #ec6922;
  color: #f5f8fa;
  line-height: 2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem; }
  .ur-discount-timer__text {
    font-size: 1rem; }
  .ur-discount-timer__time {
    font-size: 1rem; }
  .ur-discount-timer__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    vertical-align: middle; }
  .ur-discount-timer__icon-big {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    vertical-align: text-bottom; }
  @media (min-width: 992px) {
    .ur-discount-timer {
      line-height: 2.5rem; }
      .ur-discount-timer__text {
        font-size: 1.4rem; }
      .ur-discount-timer__icon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        vertical-align: middle; }
      .ur-discount-timer__icon-big {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        vertical-align: text-bottom; }
      .ur-discount-timer__prefix-small {
        font-size: 1.2rem; }
      .ur-discount-timer__time {
        font-size: 1.4rem;
        margin-left: 0.2rem; }
      .ur-discount-timer span {
        vertical-align: middle;
        display: inline-block; } }

.border-color-morningshop {
  border-color: #ec6922; }

.border-color-proteinshop {
  border-color: #2080f8; }

.border-color-default {
  border-color: #5c6ac4; }

.ur-category-icon {
  margin-top: 15px;
  margin-bottom: 15px;
  list-style: none; }
  .ur-category-icon__icon {
    display: block;
    height: 50%;
    width: 100%;
    padding: 0.2rem;
    box-sizing: border-box; }
  .ur-category-icon__img {
    width: 100%;
    overflow: hidden;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    margin: auto;
    margin-bottom: 0.5rem; }
    .ur-category-icon__img img {
      object-fit: cover; }
  .ur-category-icon__img.no-border {
    border-width: 0px;
    border-radius: 0px; }
  .ur-category-icon__text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    height: 1.3rem;
    line-height: 1.2rem;
    text-align: center; }
  .ur-category-icon__item {
    padding: 10px;
    max-width: 45%;
    margin-left: 0.5rem; }
    .ur-category-icon__item__name {
      text-align: center;
      font-size: 1rem;
      font-weight: normal; }
    .ur-category-icon__item a {
      display: inline-block;
      font-size: 0px;
      width: 100%;
      max-width: 100px; }
  .ur-category-icon__list-item {
    padding: 15px; }
    .ur-category-icon__list-item__name {
      text-align: left;
      display: inline; }
  .ur-category-icon__promotion {
    height: 3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    border-top: 1px solid #f4f5fa; }
    .ur-category-icon__promotion__icon {
      line-height: 3rem;
      text-align: right; }
    .ur-category-icon__promotion__name {
      line-height: 3rem; }
  @media (min-width: 768px) {
    .ur-category-icon__img {
      max-width: 60px;
      max-height: 60px;
      border-width: 0px;
      border-style: none; } }
  @media (min-width: 992px) {
    .ur-category-icon__text {
      font-size: 1.1rem; } }

/**
* ==== Style Extenstion ==== */
/**
* Memberinfo
*/
.ur-memberinfo {
  height: 150px;
  background-image: url("https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/member-banner-320.jpg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.9;
  padding: 20px 10px; }
  .ur-memberinfo__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #5c6ac4;
    color: #f5f8fa; }
    .ur-memberinfo__avatar i {
      font-size: 40px;
      text-align: center; }
  .ur-memberinfo__name {
    font-weight: 400;
    font-size: 1.2rem;
    margin-left: 0.2rem; }
    .ur-memberinfo__name img {
      width: 50px;
      vertical-align: middle;
      margin-left: 0.2rem; }
  .ur-memberinfo__action__button {
    width: 100%;
    padding: 5px;
    box-sizing: border-box; }
  .ur-memberinfo__action img {
    width: 40px;
    height: 40px;
    background-color: #f5f8fa;
    border-radius: 10px;
    margin-right: 5px; }

.ur-member-utilities {
  background-color: #ffffff;
  text-align: center;
  padding: 1.25rem 0.625rem 1.25rem 0.625rem;
  border-bottom: 1px solid #f4f5fa; }
  .ur-member-utilities__icon, .ur-member-utilities__title {
    display: block;
    font-size: 0.8125rem; }
  .ur-member-utilities__icon {
    font-size: 34px;
    color: #5c6ac4; }
  .ur-member-utilities__items a {
    display: block;
    padding: 0.8rem; }
  .ur-member-utilities__items__selected {
    display: block;
    padding: 0.8rem;
    background-color: #f4f5fa; }

.ur-category__sidebar {
  background-color: #ffffff;
  padding-top: 10px;
  max-height: 80%; }
  .ur-category__sidebar__item {
    min-height: 30px;
    line-height: 30px;
    border-radius: 15px;
    text-align: center;
    margin-bottom: 10px;
    color: #464c51;
    overflow: hidden;
    display: table; }
    .ur-category__sidebar__item span,
    .ur-category__sidebar__item i {
      display: table-cell;
      padding: 0px; }
    .ur-category__sidebar__item i {
      font-size: 1.4rem; }
    .ur-category__sidebar__item span {
      width: 80%;
      font-size: 0.8rem; }
    .ur-category__sidebar__item i {
      width: 20%;
      vertical-align: middle; }
    .ur-category__sidebar__item a {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      display: table-row; }
  .ur-category__sidebar__selected {
    display: table; }
    .ur-category__sidebar__selected span {
      width: 100%;
      display: table-cell;
      padding: 0px; }
    .ur-category__sidebar__selected a {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      display: table-row; }
    .ur-category__sidebar__selected__morningshop {
      background-color: #ec6922;
      color: #f5f8fa; }
      .ur-category__sidebar__selected__morningshop:hover {
        color: #f5f8fa; }
    .ur-category__sidebar__selected__proteinshop {
      background-color: #2080f8;
      color: #f5f8fa; }
      .ur-category__sidebar__selected__proteinshop:hover {
        color: #f5f8fa; }
    .ur-category__sidebar__selected__default {
      background-color: #5c6ac4;
      color: #f5f8fa; }
      .ur-category__sidebar__selected__default:hover {
        color: #f5f8fa; }

.ur-category__link-color a {
  color: #0000ee;
  text-decoration: underline; }

.ur-categorylist__menu {
  max-width: 100%;
  border: 1px solid #b2b7dd;
  background-color: #ffffff; }
  .ur-categorylist__menu__fixed {
    width: 30%;
    position: fixed;
    top: 20%; }
  .ur-categorylist__menu__item {
    position: relative;
    height: 1.5rem;
    line-height: 1.5rem;
    border-bottom: 1px solid #b2b7dd;
    padding: 1rem;
    cursor: pointer; }
    .ur-categorylist__menu__item a {
      display: block;
      width: 100%;
      height: 100%; }
    .ur-categorylist__menu__item span {
      display: inline-block;
      width: 78%;
      height: 100%;
      vertical-align: middle; }
    .ur-categorylist__menu__item i {
      width: 20%;
      text-align: right;
      vertical-align: middle; }
    .ur-categorylist__menu__item:hover {
      background-color: #5c6ac4;
      color: #f5f8fa; }
      .ur-categorylist__menu__item:hover a {
        color: #f5f8fa; }
    .ur-categorylist__menu__item:hover .ur-categorylist__submenu__item a {
      color: #464c51; }

.ur-categorylist__submenu {
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 6010;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 2px 0px 8px #9fa9b2;
  padding: 1rem;
  border-radius: 3px; }
  .ur-categorylist__submenu__item {
    cursor: pointer;
    padding: 0.5rem; }
    .ur-categorylist__submenu__item a {
      display: block;
      width: 100%;
      height: 100%; }
    .ur-categorylist__submenu__item:hover {
      background-color: #5c6ac4; }
      .ur-categorylist__submenu__item:hover ~ .__menu__item {
        background-color: #5c6ac4; }
      .ur-categorylist__submenu__item:hover a {
        color: #f5f8fa !important; }
  .ur-categorylist__submenu__expand {
    height: 7.5rem; }

.ur-category-desktop__section__content {
  padding: 0px 10% 0px 10%; }

.ur-category-desktop__others {
  padding-top: 1.5rem;
  margin-bottom: 1.5rem; }
  .ur-category-desktop__others__title {
    margin-bottom: 2rem; }
  .ur-category-desktop__others__content {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden; }

/* width */
.ur-category-desktop__others__content::-webkit-scrollbar {
  height: 5px; }

/* Track */
.ur-category-desktop__others__content::-webkit-scrollbar-track {
  background: #f5f8fa; }

/* Handle */
.ur-category-desktop__others__content::-webkit-scrollbar-thumb {
  background: #e6e8f4; }

/* Handle on hover */
.ur-category-desktop__others__content::-webkit-scrollbar-thumb:hover {
  background: #e6e8f4; }

.small-category-morningshop__item a, .small-category-proteinshop__item a, .small-category-default__item a {
  width: 100%;
  display: block;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.8rem;
  text-align: center;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem; }

.small-category-morningshop, .small-category-proteinshop, .small-category-default {
  width: 90px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  max-height: 60%;
  overflow-y: scroll;
  overflow-x: hidden; }

.small-category-morningshop {
  border: 1px solid #ec6922; }
  .small-category-morningshop__item a:hover {
    background-color: #ec6922;
    color: #ffffff; }

.small-category-proteinshop {
  border: 1px solid #2080f8; }
  .small-category-proteinshop__item a:hover {
    background-color: #2080f8;
    color: #ffffff; }

.small-category-default {
  border: 1px solid #5c6ac4; }
  .small-category-default__item a:hover {
    background-color: #5c6ac4;
    color: #ffffff; }

.small-category-bigger {
  width: 100%; }
  .small-category-bigger-morningshop {
    background-color: #ffffff;
    margin-top: 1rem;
    border: 1px solid #ec6922; }
  .small-category-bigger-proteinshop {
    background-color: #ffffff;
    margin-top: 1rem;
    border: 1px solid #2080f8; }
  .small-category-bigger-default {
    background-color: #ffffff;
    margin-top: 1rem;
    border: 1px solid #5c6ac4; }

.ur-filter-popup__option, .ur-filter-popup__option__selected {
  background-color: #f4f5fa;
  color: #464c51;
  border: 1px solid transparent;
  border-radius: 5px;
  display: block;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-size: 0.85rem;
  overflow: hidden;
  margin-bottom: 0.5rem; }

.ur-filter-popup {
  z-index: 5000;
  position: fixed;
  background-color: #f5f8fa;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%; }
  @media (min-width: 992px) {
    .ur-filter-popup {
      background-color: #ffffff;
      max-height: 75vh;
      position: relative;
      left: 0;
      z-index: 102; }
      .ur-filter-popup .ur-page-header {
        display: none; } }
  .ur-filter-popup input[type="checkbox"] {
    visibility: hidden;
    width: 0px;
    height: 0px;
    padding: 0px;
    margin: 0px; }
  .ur-filter-popup__section {
    height: 100%;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 110px; }
    @media (min-width: 992px) {
      .ur-filter-popup__section {
        height: 75vh; } }
  .ur-filter-popup__title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem; }
  .ur-filter-popup__option__selected {
    background-color: #f5f8fa;
    color: #5c6ac4;
    border: 1px solid #5c6ac4; }
  .ur-filter-popup__button a {
    color: white; }
  .ur-filter-popup__buttongroup {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #f5f8fa; }
    @media (min-width: 992px) {
      .ur-filter-popup__buttongroup {
        background-color: #ffffff; } }

.ur-filter-bar {
  height: 45px;
  width: 100%;
  font-size: 13px;
  color: #4c4c4e;
  background-color: #ffffff; }
  @media (min-width: 992px) {
    .ur-filter-bar {
      z-index: 3066;
      height: 45px;
      font-size: 1rem;
      letter-spacing: 0.2px; }
      .ur-filter-bar span {
        line-height: 45px; } }
  .ur-filter-bar a {
    display: block;
    text-align: center;
    width: 98%;
    border-left: 1px solid #f4f5fa;
    line-height: 45px; }
  .ur-filter-bar__filter__options {
    display: block;
    text-align: center;
    width: 98%;
    border-left: 1px solid #f4f5fa;
    line-height: 45px;
    cursor: pointer; }
  .ur-filter-bar__item {
    height: 100%;
    border-bottom: 1px solid #f4f5fa;
    padding: 0px; }
    .ur-filter-bar__item__selected {
      height: 100%;
      padding: 0px;
      box-sizing: border-box;
      border-bottom: 3px solid #5c6ac4;
      box-sizing: border-box; }
    .ur-filter-bar__item__disabled {
      padding: 0px; }
      .ur-filter-bar__item__disabled a {
        color: #9fa9b2;
        cursor: not-allowed;
        pointer-events: none; }
  .ur-filter-bar .disable {
    color: grey;
    opacity: 0.8; }

.ur-loading-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem; }

.ur-loading-wrapper__overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 6000;
  background-color: rgba(250, 250, 250, 0.8);
  display: flex;
  justify-content: center;
  align-items: center; }

/**
*
*/
.order-summary {
  background-color: #f5f8fa;
  padding: 10px 15px 10px 15px;
  margin: 10px 0px 10px 0px; }
  .order-summary__title {
    border-bottom: 1px solid #c4cdd5;
    font-weight: bold;
    font-size: 16px;
    padding: 15px 0px 15px 0px; }
  .order-summary__content {
    padding: 5px 0px 5px 0px; }

.ur-order-item {
  padding: 10px 0px 10px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid #f4f5fa; }
  .ur-order-item img {
    max-height: 200px; }

.ur-order-list__status {
  display: inline-block;
  min-width: 50px;
  text-align: center;
  padding: 5px;
  margin-right: 5px;
  border-radius: 5px; }

.ur-order-list__item {
  padding: 1rem; }
  @media (min-width: 992px) {
    .ur-order-list__item {
      max-width: 80%;
      margin: auto;
      height: 100%;
      border-bottom: 1px solid #f4f5fa;
      padding: 1rem 0px; } }

.ur-order-cance-button {
  padding: 10px; }

/**
* order shipping history
*/
.order-history__wrapper {
  padding: 10px;
  box-sizing: border-box; }

.order-history__item {
  height: 30px;
  vertical-align: middle;
  color: #AAAAAA; }
  .order-history__item__right {
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px; }
  .order-history__item__left {
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
  .order-history__item__line {
    display: inline-block;
    height: 30px;
    background-color: transparent;
    vertical-align: middle;
    position: relative;
    width: 10px; }
    .order-history__item__line:after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 5px;
      height: 100%;
      border-right: 1px solid #fbeae5;
      top: 0px;
      left: 0px;
      z-index: -1; }
  .order-history__item__dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    vertical-align: middle;
    border-radius: 50%;
    background-color: #ed6347; }
  .order-history__item:nth-of-type(1) {
    color: #FF4500; }

.ur-review__popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  box-sizing: border-box;
  padding: 1rem; }

.ur-review__section {
  padding: 1rem;
  margin: auto;
  max-width: 800px; }

.ur-review__item {
  border-top: 1px solid #f4f5fa;
  padding: 10px; }

.ur-review__avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #f4f5fa;
  text-align: center;
  line-height: 3rem; }

.ur-review__star {
  font-size: 1.5rem; }
  .ur-review__star__full {
    color: #ffbb00;
    font-size: 20px; }
  .ur-review__star__empty {
    color: #dce5ed;
    font-size: 20px; }

.ur-review__button {
  margin: 0;
  border: 0;
  background: white;
  height: 100%;
  width: 100%;
  display: block; }

.ur-review-page__layout {
  width: 40%;
  margin: 0 auto; }

.ur-review-page__section {
  padding: 16px; }
  .ur-review-page__section__title {
    margin-right: 24px; }

.ur-review-page__form {
  padding: 24px;
  padding-top: 0; }

.ur-review-page__h1 {
  font-size: 2rem; }

.ur-review-page__icon {
  font-size: 28px; }

.ur-review-page__textarea {
  margin: 0;
  margin-top: 8px;
  border-color: #464c51;
  border-radius: 0;
  height: 125px; }

.ur-review-page__button-black {
  background: #464c51;
  padding: 8px;
  width: 100%;
  border-color: #464c51;
  border-radius: 0; }

.ur-review-page__spacing {
  margin-left: 20px; }

.ur-review-page__popup-red {
  background: #cf4327;
  padding: 4px;
  width: 60%;
  border-color: #cf4327; }

.ur-review-page__content {
  width: 40%;
  margin: 0 auto;
  background: #ffffff;
  padding: 28px 20px 36px 20px; }
  @media screen and (min-width: 360px) and (max-width: 992px) {
    .ur-review-page__content {
      width: 80%; } }

.ur-review-page__bottom {
  margin-bottom: 60px; }

.ur-cart-button {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.9rem; }
  @media (min-width: 992px) {
    .ur-cart-button {
      font-size: 1rem; } }
  .ur-cart-button__badge {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: #5c6ac4;
    line-height: 2.2rem;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0; }
    .ur-cart-button__badge__icon {
      font-size: 1.3rem;
      color: #f5f8fa;
      vertical-align: text-bottom;
      margin-left: 0.2rem; }

.ur-border-button {
  border: 2px solid #5c6ac4;
  background-color: #ffffff;
  color: #5c6ac4;
  padding: 5px;
  margin: 5px;
  max-width: 100px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (min-width: 992px) {
    .ur-border-button {
      max-width: 120px; } }
  .ur-border-button.show {
    color: #f5f8fa;
    background-color: #5c6ac4; }
  .ur-border-button:disabled {
    opacity: 0.9; }
  .ur-border-button a {
    display: inline-block;
    height: 100%;
    width: 100%;
    color: #5c6ac4; }

.ur-checkout-button {
  border-radius: 3px;
  font-weight: 400;
  font-size: 1.14rem;
  display: inline-block;
  width: 46%;
  line-height: 24px;
  border: 2px solid #5c6ac4;
  background-color: #5c6ac4;
  color: #f5f8fa;
  padding: 8px;
  text-align: center; }
  .ur-checkout-button:hover {
    background-color: #5c6ac4; }

.ur-banner {
  position: relative; }
  .ur-banner__content {
    max-width: 1600px;
    margin: auto; }
    @media (min-width: 992px) {
      .ur-banner__content {
        width: 80%; } }
  .ur-banner:nth-of-type(odd) {
    background-color: #f5f8fa; }
  .ur-banner__title {
    font-weight: bold;
    color: #464c51;
    padding: 1.25rem 0.625rem 0.625rem 0.625rem; }
  .ur-banner__image {
    width: 100%;
    overflow: hidden; }
    .ur-banner__image a {
      width: 100%; }
    .ur-banner__image img {
      width: 100%;
      object-fit: cover; }
  .ur-banner__see-all {
    padding: 1.25rem 0 0.625rem 0.625rem; }
    .ur-banner__see-all span {
      display: inline-block;
      vertical-align: middle; }
    .ur-banner__see-all i {
      vertical-align: middle; }

.ur-section {
  padding: 10px; }
  .ur-section__title {
    position: relative;
    font-weight: bold;
    color: #464c51;
    padding: 20px 0px 10px 10px; }
    .ur-section__title__see {
      display: block;
      height: 100%;
      width: 100%; }
  .ur-section__banner a {
    display: block;
    width: 100%;
    height: 100%; }

.ur-cart-banner {
  display: flex;
  cursor: pointer; }
  .ur-cart-banner__vipcard-img {
    flex: 3;
    position: relative;
    background-color: #2b2c2a; }
    .ur-cart-banner__vipcard-img--big {
      display: block;
      width: 100%; }
      @media (max-width: 991px) {
        .ur-cart-banner__vipcard-img--big {
          display: none; } }
    .ur-cart-banner__vipcard-img--small {
      display: none; }
      @media (max-width: 991px) {
        .ur-cart-banner__vipcard-img--small {
          display: block; } }
  .ur-cart-banner__non-vip {
    display: block;
    position: relative;
    cursor: pointer; }
    @media (max-width: 425px) {
      .ur-cart-banner__non-vip {
        display: none; } }
    .ur-cart-banner__non-vip__img-wrap {
      position: relative; }
      .ur-cart-banner__non-vip__img-wrap img {
        width: 100%; }
      .ur-cart-banner__non-vip__img-wrap p {
        position: absolute;
        bottom: 2%;
        left: 35%;
        color: #eee; }
        @media (max-width: 750px) {
          .ur-cart-banner__non-vip__img-wrap p {
            left: 30%;
            font-size: 12px; } }
    .ur-cart-banner__non-vip__text {
      position: absolute;
      right: 2%;
      bottom: 10%;
      font-size: 22px;
      font-weight: 600; }
      @media (max-width: 1060px) {
        .ur-cart-banner__non-vip__text {
          font-size: 20px; } }
      @media (max-width: 860px) {
        .ur-cart-banner__non-vip__text {
          font-size: 17px; } }
      @media (max-width: 750px) {
        .ur-cart-banner__non-vip__text {
          font-size: 15px; } }
      @media (max-width: 650px) {
        .ur-cart-banner__non-vip__text {
          font-size: 12px; } }
    .ur-cart-banner__non-vip__reversed-text {
      background-color: black;
      color: #eee;
      padding: 0 4px; }

.ur-cart-banner-mobile {
  display: none;
  background-color: #2b2c2a; }
  @media (max-width: 425px) {
    .ur-cart-banner-mobile {
      display: block; } }
  .ur-cart-banner-mobile__bottom-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .ur-cart-banner-mobile__bottom {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box; }
  .ur-cart-banner-mobile__vip-hand {
    max-width: 100%;
    height: auto;
    margin-right: 15px; }
  .ur-cart-banner-mobile__text {
    border-left: 1px solid black;
    padding-left: 10px; }
  .ur-cart-banner-mobile__reversed-text {
    background-color: #2b2c2a;
    color: #eee;
    font-weight: 700;
    padding: 0 4px; }

.ur-cart-banner-vip-web__product {
  display: flex;
  flex: 1;
  align-items: center; }
  @media (max-width: 991px) {
    .ur-cart-banner-vip-web__product {
      display: none; } }
  .ur-cart-banner-vip-web__product__img {
    flex: 1; }
    .ur-cart-banner-vip-web__product__img > img {
      width: 100%; }

.ur-cart-banner-vip-web__detail {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .ur-cart-banner-vip-web__detail__title {
    display: inline-block;
    padding: 0 5px;
    background-color: #2b2c2a;
    font-weight: 600;
    font-size: 20px;
    color: #e8cfa8;
    margin-bottom: 4px; }
    @media (max-width: 760px) {
      .ur-cart-banner-vip-web__detail__title {
        font-size: 18px; } }
    @media (max-width: 600px) {
      .ur-cart-banner-vip-web__detail__title {
        font-size: 14px; } }
    @media (max-width: 425px) {
      .ur-cart-banner-vip-web__detail__title {
        font-size: 12px; } }
  .ur-cart-banner-vip-web__detail__price {
    font-weight: 600;
    color: #2b2c2a;
    font-size: 25px;
    margin-bottom: 4px; }
    @media (max-width: 760px) {
      .ur-cart-banner-vip-web__detail__price {
        font-size: 22px; } }
    @media (max-width: 600px) {
      .ur-cart-banner-vip-web__detail__price {
        font-size: 18px; } }
    @media (max-width: 425px) {
      .ur-cart-banner-vip-web__detail__price {
        font-size: 12px; } }
  .ur-cart-banner-vip-web__detail__name {
    font-weight: 600;
    color: #2b2c2a;
    font-size: 12px; }

.ur-cart-banner-vip-mobile__product {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-width: 0; }
  @media (min-width: 992px) {
    .ur-cart-banner-vip-mobile__product {
      display: none; } }
  .ur-cart-banner-vip-mobile__product__img {
    height: 65%;
    overflow: hidden; }
    .ur-cart-banner-vip-mobile__product__img > img {
      width: auto;
      max-height: 100%; }

.ur-cart-banner-vip-mobile__detail {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 0; }
  .ur-cart-banner-vip-mobile__detail__title {
    background-color: #2b2c2a;
    font-weight: 600;
    color: #e8cfa8; }
  .ur-cart-banner-vip-mobile__detail__price-wrap {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 25px; }
    @media (max-width: 600px) {
      .ur-cart-banner-vip-mobile__detail__price-wrap {
        font-size: 16px; } }
    @media (max-width: 425px) {
      .ur-cart-banner-vip-mobile__detail__price-wrap {
        font-size: 12px; } }
  .ur-cart-banner-vip-mobile__detail__price {
    font-weight: 600;
    color: #2b2c2a; }
  .ur-cart-banner-vip-mobile__detail__name {
    font-weight: 600;
    color: #2b2c2a;
    width: 100%;
    padding: 0 5px;
    overflow: hidden;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box; }
    @media (max-width: 600px) {
      .ur-cart-banner-vip-mobile__detail__name {
        font-size: 14px; } }
    @media (max-width: 425px) {
      .ur-cart-banner-vip-mobile__detail__name {
        font-size: 12px; } }

.ur-productlist-page {
  background-color: #f5f8fa;
  padding-bottom: 1.5rem; }
  @media (min-width: 992px) {
    .ur-productlist-page__content__produtlist {
      box-sizing: border-box; } }
  .ur-productlist-page__category-img img {
    width: 100%;
    height: auto;
    max-height: 100%;
    margin: auto; }
  .ur-productlist-page__title {
    background-color: #ffffff;
    padding-left: 10%;
    box-sizing: border-box;
    display: none; }
    @media (min-width: 992px) {
      .ur-productlist-page__title {
        display: flex;
        padding-top: 1rem; } }
    .ur-productlist-page__title__text {
      height: 8rem;
      line-height: 8rem;
      letter-spacing: 0.2rem;
      max-width: 1600px;
      font-size: 2rem; }
  @media (min-width: 992px) {
    .ur-productlist-page {
      padding-bottom: 30px; } }

.left-filter-container {
  width: 22%;
  vertical-align: top;
  padding: 1.5rem 1.5rem 0px 5%;
  box-sizing: border-box; }

@media (min-width: 992px) {
  .filter-open-style {
    width: 90%;
    padding: 0; } }

@media (min-width: 1600px) {
  .filter-open-style {
    width: 95%;
    padding: 0; } }

.filter-close-style.width-100 {
  width: 100%; }

@media (min-width: 992px) {
  .filter-close-style {
    padding-left: 10%;
    padding-right: 10%; } }

.ur-pagination {
  padding: 2rem; }
  .ur-pagination__pages {
    cursor: pointer;
    border-radius: 3px; }
    .ur-pagination__pages a {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center; }
  .ur-pagination__active {
    border-width: 1px;
    border-style: solid; }
    .ur-pagination__active a {
      color: #f5f8fa; }

.background-morningshop {
  background-color: #ec6922; }

.background-proteinshop {
  background-color: #2080f8; }

.background-default {
  background-color: #5c6ac4; }

.modal {
  padding: 2rem 1rem 1rem 1rem; }

.modal > .header {
  width: 100%;
  font-size: 1.2rem;
  color: #5c6ac4;
  text-align: left;
  line-height: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem; }

.modal > .content {
  width: 100%;
  font-size: 1.1rem;
  text-align: center;
  padding-left: 2px;
  letter-spacing: 0.2px;
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.modal > .actions {
  width: 100%;
  margin: auto;
  text-align: center; }
  .modal > .actions button {
    background-color: #cf4327;
    color: #f5f8fa;
    border-color: #cf4327;
    height: 2.5rem;
    width: 100%; }
  @media (min-width: 992px) {
    .modal > .actions {
      width: 50%; } }
  .modal > .actions .leave-button {
    background-color: #ffffff;
    color: #212121;
    border: none;
    border-radius: 0px;
    margin-right: 5px;
    margin-left: 5px;
    height: 40px;
    line-height: 40px;
    width: 48%; }
  .modal > .actions .action-button {
    background-color: #494949;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    margin-right: 5px;
    margin-left: 5px;
    height: 40px;
    line-height: 40px;
    width: 48%; }
  @media (min-width: 992px) {
    .modal > .actions .leave-button {
      border-radius: 3px; }
    .modal > .actions .action-button {
      background-color: #131b6b;
      color: #ffffff; } }

.modal > .halfbutton {
  width: 100%;
  margin: auto;
  text-align: center; }
  .modal > .halfbutton button {
    width: 45%;
    margin-right: 0.5rem; }

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 5px;
  top: 5px;
  font-size: 1/6rem;
  background: #ffffff; }

.modal .action-button {
  width: 48%;
  font-size: 1rem;
  margin: 0.2rem;
  border: 2px solid #5c6ac4;
  background-color: #5c6ac4; }

.modal .cancel-button {
  width: 48%;
  font-size: 1rem;
  margin: 0.2rem;
  background-color: #ffffff;
  border: 2px solid #5c6ac4;
  color: #5c6ac4; }

.popup-content {
  max-height: 70vh;
  overflow-x: hidden;
  background-color: #ffffff;
  width: 70%; }
  @media (min-width: 992px) {
    .popup-content {
      width: 50%; } }

.ur-adult-content {
  background-color: #222222;
  border-radius: 1rem;
  color: #f5f8fa; }
  .ur-adult-content img {
    display: none; }
  .ur-adult-content__alert {
    color: #e90f0f;
    font-size: 1.5rem; }
  @media (min-width: 992px) {
    .ur-adult-content img {
      display: flex; }
    .ur-adult-content__alert {
      display: none; } }

.modal .iconImg .img {
  background-image: url("https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/present_64.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 64px;
  height: 64px;
  margin: auto; }

.ur-editor-choice {
  box-sizing: border-box;
  padding: 10px; }
  .ur-editor-choice img {
    width: 100%;
    max-width: 100%;
    border-radius: 15px; }

.ur-coupon-list .ur-page-header {
  z-index: initial;
  width: 100%; }
  @media (min-width: 992px) {
    .ur-coupon-list .ur-page-header {
      display: none; } }

@media (min-width: 992px) {
  .ur-coupon-tabs {
    background-color: #f4f5fa; } }

.infinite-item {
  width: 46%; }
  @media (min-width: 768px) {
    .infinite-item {
      width: 32%; } }
  @media (min-width: 992px) {
    .infinite-item {
      width: 20%; } }
  @media (min-width: 1200px) {
    .infinite-item {
      width: 15%; } }

.ur-product-card, .ur-product-card-small {
  border: 1px solid #f4f5fa;
  border-radius: 3px;
  margin-top: 0.8rem;
  overflow: hidden;
  padding: 0.5rem;
  background-color: #fff;
  cursor: pointer; }

.ur-product-card a {
  height: 100%;
  width: 100%;
  display: block; }

.ur-product-card__img {
  width: 100%;
  height: 11rem;
  text-align: center;
  overflow: hidden;
  position: relative; }
  .ur-product-card__img img {
    width: auto;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: auto; }

.ur-product-card__img-outOfStock {
  width: 100%;
  height: 11rem;
  overflow: hidden;
  position: relative;
  text-align: center; }
  .ur-product-card__img-outOfStock img {
    width: auto;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: auto; }
  .ur-product-card__img-outOfStock:before {
    content: "";
    background-image: url("https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/out-of-stock.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }

.ur-product-card__tag {
  font-size: 0.7rem;
  border-radius: 3px;
  background-color: #ed6347;
  color: #f5f8fa;
  padding: 0.2rem 0.4rem;
  text-align: center;
  max-width: 40%; }

.ur-product-card__details {
  width: 100%;
  height: 5rem; }
  .ur-product-card__details__button {
    text-align: right; }

.ur-product-card__name {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  height: 3rem;
  line-height: 1.5rem;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem; }

.ur-product-card__discount {
  position: absolute;
  top: 0;
  padding: 0.2rem 0.8rem;
  background-color: #ed6347;
  color: #f4f5fa;
  font-size: 0.8rem;
  letter-spacing: 0.2px; }

.ur-product-card-small {
  margin-right: 0.2rem;
  position: relative; }
  .ur-product-card-small a {
    height: 100%;
    width: 100%;
    display: block; }
  .ur-product-card-small__img {
    width: 100%;
    height: 8.5rem;
    text-align: center;
    overflow: hidden;
    position: relative; }
    .ur-product-card-small__img img {
      width: auto;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      margin: auto; }
  .ur-product-card-small__img-outOfStock {
    width: 100%;
    height: 8.5rem;
    overflow: hidden;
    position: relative;
    text-align: center; }
    .ur-product-card-small__img-outOfStock img {
      width: auto;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      margin: auto; }
    .ur-product-card-small__img-outOfStock:before {
      content: "";
      background-image: url("https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/out-of-stock.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  .ur-product-card-small__tag {
    font-size: 0.6rem;
    border-radius: 3px;
    background-color: #ed6347;
    color: #f5f8fa;
    padding: 0.2rem 0.4rem;
    text-align: center;
    max-width: 40%; }
  .ur-product-card-small__name {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    height: 3rem;
    line-height: 1.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem; }
  .ur-product-card-small__details {
    width: 100%;
    height: 5rem; }
    .ur-product-card-small__details__button {
      text-align: center; }
  .ur-product-card-small__timer {
    background-color: #ed6347;
    color: #f5f8fa;
    padding: 5px 10px;
    float: left; }
  .ur-product-card-small__discount {
    position: absolute;
    top: 0;
    padding: 0.2rem 0.8rem;
    background-color: #ed6347;
    color: #f4f5fa;
    font-size: 0.8rem;
    letter-spacing: 0.2px; }

.group-product-card__wrapper {
  height: calc(100% - 0.8rem); }

.group-product-card__content {
  height: calc(100% - 1.14rem); }

.ur-wishlist-button {
  bottom: 1.75rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  z-index: 2;
  text-align: center;
  line-height: 0.8rem; }
  .ur-wishlist-button__added {
    height: 100%;
    width: 100%;
    background-image: url("../assets/heart_active_60.png");
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat; }
  .ur-wishlist-button__unadded {
    height: 100%;
    width: 100%;
    background-image: url("../assets/heart_inactive_60.png");
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat; }
    .ur-wishlist-button__unadded:hover {
      background-image: url("../assets/heart_hover_60.png");
      background-position: center;
      background-size: auto 100%;
      background-repeat: no-repeat; }
  .ur-wishlist-button__count {
    font-size: 0.8rem; }

.ur-wishlist-button__page {
  margin: 0;
  background-color: #ffffff;
  border: none;
  color: #333333;
  width: 100%;
  height: 100%;
  display: block; }
  .ur-wishlist-button__page .ur-wishlist-button {
    position: relative;
    right: 0;
    bottom: 0; }

.ur-limited-items__timer {
  color: #e93d16;
  font-weight: 800;
  font-size: 1rem; }
  .ur-limited-items__timer span:nth-of-type(2) {
    margin-right: 0.2rem;
    margin-left: 0.2rem; }
  .ur-limited-items__timer span:nth-of-type(odd) {
    background-color: #e93d16;
    color: #f5f8fa;
    line-height: 1.2rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 0.2rem; }
  @media (min-width: 360px) {
    .ur-limited-items__timer {
      font-size: 1.1rem; } }
  @media (min-width: 992px) {
    .ur-limited-items__timer {
      font-size: 1.2rem; } }

.ur-limited-items__seeall {
  color: #cf4327;
  position: relative;
  font-size: 0.8rem;
  display: flex; }
  .ur-limited-items__seeall i {
    vertical-align: sub;
    font-size: 1.1rem; }
  .ur-limited-items__seeall span {
    background-color: transparent;
    border: 1px solid #cf4327;
    border-radius: 1rem;
    padding-left: 0.6rem; }
  .ur-limited-items__seeall:after {
    content: "N";
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    color: #f5f8fa;
    position: absolute;
    top: -0.5rem;
    right: -0.2rem;
    background-color: #e93d16;
    text-align: center;
    line-height: 1rem;
    font-size: 0.6rem; }
  @media (min-width: 992px) {
    .ur-limited-items__seeall {
      font-size: 1rem; }
      .ur-limited-items__seeall i {
        vertical-align: middle;
        font-size: 1.2rem; }
      .ur-limited-items__seeall:after {
        font-size: 0.75rem;
        width: 1.1rem;
        height: 1.1rem;
        line-height: 1.1rem;
        top: -0.6rem; } }

/* The container */
.ur-radiobutton {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2rem;
  height: 2rem;
  vertical-align: -80%;
  display: inline-block; }
  .ur-radiobutton__checkmark {
    height: 1rem;
    width: 1rem;
    background-color: #ffffff;
    border: 2px solid #5c6ac4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0; }
    .ur-radiobutton__checkmark:after {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: white;
      content: "";
      display: none; }
  .ur-radiobutton input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #5c6ac4;
    color: #5c6ac4; }
  .ur-radiobutton input:checked + .ur-radiobutton__checkmark::after {
    background: #5c6ac4;
    display: block; }

/*!
 * react-responsive-carousel css
 * https://www.npmjs.com/package/react-responsive-carousel 
*/
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer;
  max-width: 15%; }
  @media (min-width: 992px) {
    .carousel .control-arrow,
    .carousel.carousel-slider .control-arrow {
      max-width: 5%; } }

.carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100); }

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: ""; }

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none; }

.carousel .control-prev.control-arrow {
  left: 0; }

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff; }

.carousel .control-next.control-arrow {
  right: 0; }

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff; }

.carousel {
  position: relative;
  width: 100%;
  height: 100%; }

.carousel * {
  box-sizing: border-box; }

.carousel .carousel {
  position: relative; }

.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -13px;
  font-size: 18px; }

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden; }

.carousel .thumbs {
  transition: all 0.15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap; }

.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  width: 80px;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px; }

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #333;
  padding: 2px; }

.carousel .thumb img {
  vertical-align: top; }

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden; }

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px; }

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2); }

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  height: 100%;
  transition: height 0.15s ease-in; }

.carousel .slider-wrapper.axis-horizontal .slider {
  display: flex; }

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column; }

.carousel .slider-wrapper.axis-vertical {
  display: flex; }

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column; }

.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
  height: 100%; }

.carousel .slider.animated {
  transition: all 0.35s ease-in-out; }

.carousel .slide {
  min-width: 100%;
  margin: 0;
  height: 100%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat; }

.ur-homepage__carousel .control-dots {
  position: absolute; }

.ur-homepage__carousel .carousel .slide img {
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
  object-position: 50% 50%;
  max-height: 576px; }

.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0; }

.carousel .slide .legend {
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  transition: opacity 0.35s ease-in-out; }

.carousel .control-dots {
  margin: 10px 0;
  text-align: center;
  width: 100%; }

@media (min-width: 992px) {
  .carousel .control-dots {
    bottom: 0; } }

.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px #b2b7dd;
  background: #5c6ac4;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px; }

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100); }

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  background: #5c6ac4; }

.carousel:hover .slide .legend {
  opacity: 1; }

/**
* react-tabs styles
* https://github.com/reactjs/react-tabs
*/
.react-tabs {
  tap-highlight-color: none;
  width: 100%; }
  .react-tabs__tab-list {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    text-align: center; }
  .react-tabs__tab {
    flex: 1 auto;
    border-bottom: 2px solid #f4f5fa;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1rem;
    cursor: pointer;
    width: 50%;
    font-size: 1rem; }
    @media (min-width: 992px) {
      .react-tabs__tab {
        font-size: 1rem;
        width: 20%; } }
    .react-tabs__tab--selected {
      background-color: transparent;
      border-bottom: 3px solid #5c6ac4;
      color: #5c6ac4;
      font-size: bold; }
    .react-tabs__tab--disabled {
      color: #f4f5fa;
      cursor: default; }
    .react-tabs__tab:focus {
      outline: none; }
  .react-tabs__tab-panel {
    display: none; }
    .react-tabs__tab-panel--selected {
      display: block; }

@media (min-width: 992px) {
  .ur-couppin-tabs .react-tabs__tab {
    background-color: #f4f5fa;
    border-bottom: none;
    border-radius: 5px; } }

.tpfield {
  padding: 1rem;
  height: 30px;
  border: 1px solid gray;
  background-color: #fff; }

@media (min-width: 992px) {
  .tpcardform-wrapper {
    max-width: 80%;
    margin: auto; } }

.tpcardform {
  overflow: hidden;
  background-color: #ededed; }
  .tpcardform__button-group {
    text-align: right;
    padding: 1rem 0 0 1rem; }
    .tpcardform__button-group button.cancel {
      margin: 0 1rem 0 0;
      width: 45%;
      height: 3.5rem;
      background: none;
      border-color: transparent;
      color: #464c51;
      text-align: right; }
    .tpcardform__button-group button.confirm {
      margin: 0;
      width: 45%;
      height: 3.5rem; }
  @media (min-width: 992px) {
    .tpcardform__button-group button.cancel {
      width: 30%; }
    .tpcardform__button-group button.confirm {
      width: 30%; } }
  .tpcardform__title {
    padding: 0rem 0rem 1rem 0rem; }

.ur-info-page {
  padding: 0.5rem; }

.ur-tag {
  background-color: #ed6347;
  color: #f5f8fa;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap; }

.ur-member-button {
  text-align: center;
  background-color: #5c6ac4;
  color: #ffffff;
  border-radius: 3px;
  font-size: 1rem;
  height: 3rem;
  line-height: 3rem; }
  .ur-member-button:hover {
    color: #ffffff;
    background-color: #5c6ac4; }

.ur-cart-message {
  padding: 0.5rem;
  background-color: #f9f6c8; }
  .ur-cart-message__tag {
    background-color: #ed6347;
    color: #ffffff;
    border-radius: 2px; }
  .ur-cart-message__text {
    color: #464c51;
    margin-left: 0.5rem; }

.ur-store-logo {
  width: 30px;
  height: 30px;
  vertical-align: sub;
  margin-right: 10px; }
  @media (min-width: 992px) {
    .ur-store-logo {
      width: 40px;
      height: 40px; } }

.ur-promotion-bar {
  height: 50px;
  box-sizing: border-box;
  background-color: #ed6347;
  color: #f5f8fa;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  width: 100%;
  top: 0px; }
  .ur-promotion-bar__text {
    text-align: center; }
  .ur-promotion-bar__close {
    text-align: right;
    cursor: pointer; }

.ur-blocklevel-link {
  height: 100%;
  width: 100%;
  cursor: pointer; }

.menu-item-wrapper {
  width: 180px; }

.ur-cart {
  background-color: #f4f5fa;
  padding: 15px; }
  .ur-cart__count {
    padding: 10px;
    font-size: 1rem;
    background-color: #ffffff; }
  .ur-cart__title {
    font-size: 1rem; }

.default-color {
  color: #5c6ac4; }

.protein-color {
  color: #2080f8; }

.morningshop-color {
  color: #ec6922; }

.ur-product-info {
  border: 1px solid #f4f5fa;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 15px 0px 15px 0px;
  font-size: 0.8125rem;
  color: #464c51;
  overflow: hidden; }
  .ur-product-info__wrapper {
    padding: 1rem;
    background-color: #f5f8fa; }
  .ur-product-info__item {
    border-bottom: 1px solid #f4f5fa;
    padding: 8px 5px; }
    .ur-product-info__item span {
      display: inline-block;
      vertical-align: middle;
      padding-left: 5px; }
    .ur-product-info__item i {
      vertical-align: middle;
      color: #5c6ac4;
      font-size: 1.57rem; }
  .ur-product-info__info {
    padding: 5px;
    height: 100%; }
  @media (min-width: 992px) {
    .ur-product-info__wrapper {
      padding: 0;
      background-color: #ffffff; }
    .ur-product-info__item {
      font-size: 1rem; } }

.ur-register {
  padding: 0.8rem;
  box-sizing: border-box; }
  .ur-register__section {
    margin: 2rem 0 2rem 0; }

/**
* checkout navigation styles
*/
.ur-checkout-step {
  height: 45px;
  font-size: 0.875rem; }
  .ur-checkout-step__bar {
    padding: 0px;
    text-align: center;
    border-bottom: 1px solid #e6e8f4;
    background-color: #ffffff; }
    .ur-checkout-step__bar a {
      display: block;
      line-height: 45px;
      color: #e6e8f4; }
    a.ur-checkout-step__bar__active {
      color: #5c6ac4;
      border-bottom: 3px solid #5c6ac4; }
    a.ur-checkout-step__bar__inactive {
      color: #c4cdd5; }

.ur-cvs-choose__form {
  border: 1px solid #e6e8f4;
  border-radius: 5px;
  margin-right: 5px;
  max-width: 200px;
  margin-right: 5px; }
  .ur-cvs-choose__form__button {
    background-color: #ffffff;
    border: none; }

.ur-cvs-choose__button {
  border: 1px solid #e6e8f4;
  border-radius: 5px;
  max-width: 200px;
  text-align: center;
  padding: 10px 0px 10px 0px; }
  .ur-cvs-choose__button img {
    width: auto; }

.ur-cvs-choose img {
  height: 40px; }

/**
* checkout content styles e.g cartinfo, shipping...
*/
.ur-checkout-content__section {
  padding: 15px; }
  .ur-checkout-content__section__title {
    font-size: 18px;
    font-weight: bold;
    color: #464c51;
    border-bottom: 1px solid #d0d4ef;
    margin: 10px 0 10px 0;
    padding: 0px 0px 10px 0px; }
  .ur-checkout-content__section.background-grey {
    background-color: #f4f5fa; }

.hidden {
  display: none; }

.ur-slider-wrapper {
  height: "200px";
  backgroundcolor: "grey"; }

.ur-scroll-top__morningshop, .ur-scroll-top__default, .ur-scroll-top__proteinshop {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  bottom: 144px;
  right: 1rem;
  text-align: center;
  color: #f5f8fa;
  background-color: #5c6ac4;
  z-index: 2077;
  cursor: pointer;
  line-height: 40px;
  display: none; }
  .ur-scroll-top__morningshop i, .ur-scroll-top__default i, .ur-scroll-top__proteinshop i {
    vertical-align: middle;
    font-size: 1.5rem;
    margin-top: -5px; }
  @media (min-width: 992px) {
    .ur-scroll-top__morningshop, .ur-scroll-top__default, .ur-scroll-top__proteinshop {
      width: 50px;
      height: 50px;
      line-height: 50px;
      right: 1.2rem; }
      .ur-scroll-top__morningshop i, .ur-scroll-top__default i, .ur-scroll-top__proteinshop i {
        vertical-align: middle;
        font-size: 2rem;
        margin-top: -5px; } }

.ur-scroll-top__morningshop {
  background-color: #ec6922; }

.ur-scroll-top__default {
  background-color: #5c6ac4; }

.ur-scroll-top__proteinshop {
  background-color: #2080f8; }

.ur-disabled-input {
  border: none; }

.selected {
  background-color: rgab(128, 128, 128, 0.5); }

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

.ur-text-level-1 {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.2px; }

.ur-text-level-2 {
  font-size: 1.62rem;
  font-weight: 500;
  letter-spacing: 0.2px; }

.ur-text-level-3 {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.2px; }

.ur-text-level-4 {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.2px; }

.ur-text-level-5 {
  font-size: 0.8125rem;
  font-weight: 300;
  letter-spacing: 0.2px; }

.ur-text-level-6 {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.2px; }

.ur-text-level-7 {
  font-size: 0.625rem;
  font-weight: 300;
  letter-spacing: 0.2px; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.color-red {
  color: #cf4327; }

.color-white {
  color: #f5f8fa; }

.color-grey {
  color: #4d4d4d; }

.overflow-text {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  height: 3rem;
  line-height: 1.5rem;
  margin-top: 0.2rem;
  height: 4.5rem; }

/*
* Dynamic create *-color class with theme color
* .morningshop-color
* .proteinshop-color
* .default-color
*/
.default-backgroundcolor {
  background-color: #5c6ac4; }

.morningshop-backgroundcolor {
  background-color: #ec6922; }

.proteinshop-backgroundcolor {
  background-color: #2080f8; }
