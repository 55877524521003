/**
* Five page nav items
*/
.ur-tabnav {
  width: 100%;
  height: $mobileBottonNavigationHeight;
  position: fixed;
  bottom: 0;
  z-index: $navigationZindex;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);

  &__nav {
    // wrapper for items
    display: flex;
    margin: 0;
    padding: 10px;
    border-top: 1px solid #dce5ed;
    box-sizing: border-box;
  }
  &__nav-item {
    height: 100%;
    flex: 1;
    text-align: center;
    a {
      display: block;
    }
  }

  &__selected {
    color: $urmart-color;
  }
  &__icon {
    font-size: 1.8rem;
  }

  &__icon-title {
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
