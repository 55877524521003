.ur-productlist-page {
  background-color: $white;
  padding-bottom: 1.5rem;
  &__content {
    &__produtlist {
      @include respond-to("screen-md-min") {
        box-sizing: border-box;
      }
    }
  }

  &__category {
    &-img {
      img {
        width: 100%;
        height: auto;
        max-height: 100%;
        margin: auto;
      }
    }
  }

  &__title {
    background-color: #ffffff;
    padding-left: 10%;
    box-sizing: border-box;
    display: none;

    @include respond-to("screen-md-min") {
      display: flex;
      padding-top: 1rem;
    }

    &__text {
      height: 8rem;
      line-height: 8rem;
      letter-spacing: 0.2rem;
      max-width: $maxDesktopWidth;
      font-size: 2rem;
    }
  }

  @include respond-to("screen-md-min") {
    padding-bottom: 30px;
  }
}

.left-filter-container {
  width: 22%;
  vertical-align: top;
  padding: 1.5rem 1.5rem 0px 5%;
  box-sizing: border-box;
}
.filter-open-style {
  @include respond-to("screen-md-min") {
    width: 90%;
    padding: 0;
  }
  @include respond-to("screen-xl-min") {
    width: 95%;
    padding: 0;
  }
}

.filter-close-style {
  &.width-100 {
    width: 100%;
  }
  @include respond-to("screen-md-min") {
    padding-left: 10%;
    padding-right: 10%;
  }
}
