@import "../mixins/text";
/**
* Memberinfo
*/
.ur-memberinfo {
  height: 150px;
  background-image: url("https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/member-banner-320.jpg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.9;
  padding: 20px 10px;

  &__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $urmart-color;
    color: $white;
    i {
      font-size: 40px;
      text-align: center;
    }
  }

  &__name {
    @include content-text-big;
    margin-left: 0.2rem;
    img {
      width: 50px;
      vertical-align: middle;
      margin-left: 0.2rem;
    }
  }

  &__action {
    &__button {
      width: 100%;
      padding: 5px;
      box-sizing: border-box;
    }

    img {
      width: 40px;
      height: 40px;
      background-color: $white;
      border-radius: 10px;
      margin-right: 5px;
    }
  }
}
.ur-member-utilities {
  background-color: #ffffff;
  text-align: center;
  padding: 1.25rem 0.625rem 1.25rem 0.625rem;
  border-bottom: 1px solid $grey-light;

  &__icon,
  &__title {
    display: block;
    font-size: 0.8125rem;
  }
  &__icon {
    font-size: 34px;
    color: $urmart-color;
  }
  &__items {
    a {
      display: block;
      padding: 0.8rem;
    }
    &__selected {
      display: block;
      padding: 0.8rem;
      background-color: $grey-light;
    }
  }
}
